import { Badge } from "@material-ui/core";
import React from "react";
import { Header, Body, Footer, BuyFooter } from "../cardIndex";
import Adtype from './AdType';
import CornerRibbon from "react-corner-ribbon";

const Card = ({
  profile_picture,
  page_name,
  date_posted,
  fb_video_ad_link,
  objectID,
  text,
  video_links_dropbox,
  headline,
  description,
  commentss,
  shares_,
  post_reactions,
  primary_text,
  addToFav,
  removeFav,
  id,
  fav,
  outbound_URL,
  Main_Ad_Type,
  Secondary_Ad_Type,
  Revenue_Generated,
  Price_Generated,
  Top_Ad
}) => {
  // console.log('state data', Top_Ad)
  return (

    <div >

    
      <section className="post-content" >
        <Header
          page_name={page_name}
          date_posted={date_posted}
          fb_video_ad_link={fb_video_ad_link}
          profile_picture={profile_picture}
          addToFav={addToFav}
          removeFav={removeFav}
          id={id}
          fav={fav}
        />
        <Body
          id={id}
          addToFav={addToFav}
          primary_text={primary_text}
          removeFav={removeFav}
          video_links_dropbox={video_links_dropbox}
        />
        <BuyFooter headline={headline} description={description} outbound_URL={outbound_URL} />
        <Adtype
          Secondary_Ad_Type={Secondary_Ad_Type}
          Main_Ad_Type={Main_Ad_Type}
          Revenue_Generated={Revenue_Generated}
          Price_Generated={Price_Generated}
          Top_Ad={Top_Ad}
        />
        <Footer
          commentss={commentss}
          shares_={shares_}
          post_reactions={post_reactions}
        />
      </section>
    </div>

  );
};

export default Card;
