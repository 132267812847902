import React from "react";
import { Card } from "../../Components/cardIndex";
function RenderCard({
  profile_picture,
  page_name,
  date_posted,
  fb_video_ad_link,
  objectID,
  text,
  video_links_dropbox,
  headline,
  description,
  commentss,
  shares_,
  post_reactions,
  primary_text,
  fav,
  removeFav,
  id,
  Revenue_Generated,
  Price_Generated,
  Top_Ad,
  Secondary_Ad_Type,
  Main_Ad_Type
}) {
  return (
    <Card
      profile_picture={profile_picture}
      page_name={page_name}
      primary_text={primary_text}
      date_posted={date_posted}
      fb_video_ad_link={fb_video_ad_link}
      objectID={objectID}
      text={text}
      video_links_dropbox={video_links_dropbox}
      headline={headline}
      description={description}
      commentss={commentss}
      fav={fav}
      shares_={shares_}
      removeFav={removeFav}
      id={id}
      post_reactions={post_reactions}
      Revenue_Generated={Revenue_Generated}
      Price_Generated={Price_Generated}
      Top_Ad={Top_Ad}
      Secondary_Ad_Type={Secondary_Ad_Type}
      Main_Ad_Type={Main_Ad_Type}
    />
  );
}
export const Post = ({
  profile_picture,
  page_name,
  date_posted,
  fb_video_ad_link,
  objectID,
  text,
  video_links_dropbox,
  headline,
  description,
  commentss,
  shares_,
  post_reactions,
  primary_text,
  fav,
  removeFav,
  id,
  Revenue_Generated,
  Price_Generated,
  Top_Ad,
  Secondary_Ad_Type,
  Main_Ad_Type
}) => {
  return (
    <RenderCard
      profile_picture={profile_picture}
      page_name={page_name}
      primary_text={primary_text}
      date_posted={date_posted}
      fb_video_ad_link={fb_video_ad_link}
      objectID={objectID}
      text={text}
      video_links_dropbox={video_links_dropbox}
      headline={headline}
      description={description}
      commentss={commentss}
      shares_={shares_}
      post_reactions={post_reactions}
      fav={fav}
      removeFav={removeFav}
      id={id}
      Revenue_Generated={Revenue_Generated}
      Price_Generated={Price_Generated}
      Top_Ad={Top_Ad}
      Secondary_Ad_Type={Secondary_Ad_Type}
      Main_Ad_Type={Main_Ad_Type}
    />
  );
};
