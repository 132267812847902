import React from "react";
import { Card } from "../../Components/cardIndex";
function RenderCard({
  profile_picture,
  page_name,
  date_posted,
  fb_video_ad_link,
  objectID,
  text,
  video_links_dropbox,
  headline,
  description,
  commentss,
  shares_,
  post_reactions,
  primary_text,
  addToFav,
  id,
  outbound_URL,
  fav,
  Main_Ad_Type,
  Secondary_Ad_Type,
  Revenue_Generated,
  Price_Generated,
  Top_Ad,
  removeFav
}) {
  return (
    <Card
      addToFav={addToFav}
      removeFav={removeFav}
      id={id}
      profile_picture={profile_picture}
      page_name={page_name}
      primary_text={primary_text}
      date_posted={date_posted}
      fb_video_ad_link={fb_video_ad_link}
      objectID={objectID}
      text={text}
      video_links_dropbox={video_links_dropbox}
      headline={headline}
      description={description}
      commentss={commentss}
      shares_={shares_}
      fav={fav}
      post_reactions={post_reactions}
      outbound_URL={outbound_URL}
      Main_Ad_Type={Main_Ad_Type}
      Secondary_Ad_Type={Secondary_Ad_Type}
      Revenue_Generated={Revenue_Generated}
      Price_Generated={Price_Generated}
      Top_Ad={Top_Ad}
    />
  );
}
export const Post = ({
  profile_picture,
  page_name,
  date_posted,
  fb_video_ad_link,
  objectID,
  text,
  video_links_dropbox,
  headline,
  description,
  commentss,
  shares_,
  post_reactions,
  primary_text,
  addToFav,
  id,
  fav,
  outbound_URL,
  Main_Ad_Type,
  Secondary_Ad_Type,
  Revenue_Generated,
  Price_Generated,
  Top_Ad,
  removeFav
}) => {
  return (
    <div style={{ width: 400, marginRight: 10, paddingBottom:"40px"}} className="render_card">
      <RenderCard
        addToFav={addToFav}
        removeFav={removeFav}
        id={id}
        profile_picture={profile_picture}
        page_name={page_name}
        primary_text={primary_text}
        date_posted={date_posted}
        fb_video_ad_link={fb_video_ad_link}
        objectID={objectID}
        text={text}
        video_links_dropbox={video_links_dropbox}
        headline={headline}
        description={description}
        commentss={commentss}
        shares_={shares_}
        post_reactions={post_reactions}
        fav={fav}
        Main_Ad_Type={Main_Ad_Type}
        outbound_URL={outbound_URL}
        Secondary_Ad_Type={Secondary_Ad_Type}
        Revenue_Generated={Revenue_Generated}
        Price_Generated={Price_Generated}
        Top_Ad={Top_Ad}
      />
    </div>
  );
};
