import { Container, Grid, Paper, Tooltip } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import DateRangePicker from "react-daterange-picker";
import moment from "moment-range";
import "react-daterange-picker/dist/css/react-calendar.css";

import "../Card.module.css";
import { useDispatch } from "react-redux";
import allActions from "./../../../store/actions";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  underline: {
    borderBottomStyle: "solid",
  },
  mark: {
    backgroundColor: "red",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function FilterBay(props) {
  const theme = useTheme();

  const classes = useStyles();
  const [personName, setPersonName] = useState([]);
  const [adType, setAdType] = useState([]);
  const [editType, setEditType] = useState([]);
  const [Classification, setClassification] = useState([]);
  const [adScore, setAdScore] = useState([]);
  const [adSocreMin, setAdScoreMin] = useState("");
  const [adSocreMax, setAdScoreMax] = useState("");
  const [pricing, setPricing] = useState([]);
  const [pricingMin, setPricingMin] = useState("");
  const [pricingMax, setPricingMax] = useState("");
  const [verifiedFb, setVerifiedFb] = useState();
  const [verifiedIn, setVerifiedIn] = useState();
  const [vidLength, setVidLength] = useState([]);
  const [vidLengthMin, setVidLengthMin] = useState("");
  const [vidLengthMax, setVidLengthMax] = useState("");
  const [likes, setLikes] = useState([]);
  const [likesMin, setLikesMin] = useState([]);
  const [likesMax, setLikesMax] = useState([]);
  const [comments, setComments] = useState([]);
  const [commentsMin, setCommentsMin] = useState([]);
  const [commentsMax, setCommentsMax] = useState([]);
  const [shares, setShares] = useState([]);
  const [sharesMin, setSharesMin] = useState([]);
  const [sharesMax, setSharesMax] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const [refinements, setRefinements] = useState({
    personName: [],
    Classification: [],
    adScore: [],
    verifiedFb: "",
    verifiedIn: "",
    vidLength: [],
    likes: [],
    comments: [],
    shares: [],
    pricing: [],
  });

  // // REDUX
  const filterDispatch = useDispatch();

  // Use Effect - update refinements each time filter changes with none slider values
  useEffect(() => {
    // console.log("applyed ====", refinements)
    filterDispatch(allActions.filterActions.applyRefinements(refinements));
  }, [
    personName,
    Classification,
    verifiedFb,
    verifiedIn,
    adType,
    editType,
    pricing,
    adScore,
  ]);

  // handling all the refs of the sliders
  const videRef = useRef(null);
  const scoreRef = useRef(null);
  const likesRef = useRef(null);
  const commentRef = useRef(null);
  const sharesRef = useRef(null);
  const pricingRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      // console.log(scoreRef.current)
      if (scoreRef.current && !scoreRef.current.contains(event.target)) {
        document.getElementById("bloc-slider").style = "display: none";
      }
      if (videRef.current && !videRef.current.contains(event.target)) {
        document.getElementById("vide-block").style = "display: none";
      }
      if (likesRef.current && !likesRef.current.contains(event.target)) {
        document.getElementById("likes-block").style = "display: none";
      }
      if (commentRef.current && !commentRef.current.contains(event.target)) {
        document.getElementById("comments-block").style = "display: none";
      }
      if (sharesRef.current && !sharesRef.current.contains(event.target)) {
        document.getElementById("shares-block").style = "display: none";
      }
      if (pricingRef.current && !pricingRef.current.contains(event.target)) {
        document.getElementById("pricing-slider").style = "display: none";
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [scoreRef, videRef]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
    setRefinements({ ...refinements, personName: event.target.value });
  };

  const handleAdType = (event) => {
    setAdType(event.target.value);
    setRefinements({ ...refinements, adType: event.target.value });
  };

  const handleEditType = (event) => {
    setEditType(event.target.value);
    setRefinements({ ...refinements, editType: event.target.value });
  };
  const handleChangeClass = (event) => {
    setClassification(event.target.value);
    setRefinements({ ...refinements, Classification: event.target.value });
  };

  const handleDateRange = (e) => {
    setShowCalender(false)
    setRefinements({ ...refinements, dateRange: e });
    filterDispatch(
      allActions.filterActions.applyRefinements({
        ...refinements,
        dateRange: e,
      })
    );
  };

  const handleChangeScore = (e, n) => {
    // console.log("ad score")

    let val = [];
    if (e == "min") {
      val[0] = n;
      val[1] = adSocreMax;
      setAdScoreMin(n);
    } else {
      val[0] = adSocreMin;
      val[1] = n;
      setAdScoreMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 6;
    }
    if (val[1] === undefined) {
      val[1] = 10;
    }

    setAdScore(val);
    // console.log(refinements)

    setRefinements({ ...refinements, adScore: val });
    filterDispatch(
      allActions.filterActions.applyRefinements({
        ...refinements,
        adScore: val,
      })
    );
  };

  const handlePricing = (e, n) => {
    // console.log("pricings")

    let val = [];
    if (e == "min") {
      val[0] = n;
      val[1] = pricingMax;
      setPricingMin(n);
    } else {
      val[0] = pricingMin;
      val[1] = n;
      setPricingMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 0;
    }
    if (val[1] === undefined) {
      val[1] = 10000;
    }
    setPricing(val);
    // console.log(refinements)
    setRefinements({ ...refinements, pricing: val });

    filterDispatch(
      allActions.filterActions.applyRefinements({
        ...refinements,
        pricing: val,
      })
    );
  };

  const handelFb = (event) => {
    // console.log(event.target.value);
    setVerifiedFb(event.target.value);
    setRefinements({ ...refinements, verifiedFb: event.target.value });
  };
  const handleIn = (event) => {
    setVerifiedIn(event.target.value);
    setRefinements({ ...refinements, verifiedIn: event.target.value });
  };

  const handleVid = (e, n) => {
    let val = [];
    if (e == "min") {
      val[0] = n;
      val[1] = vidLengthMax;
      setVidLengthMin(n);
    } else {
      val[0] = vidLengthMin;
      val[1] = n;
      setVidLengthMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 0;
    }
    if (val[1] === undefined) {
      val[1] = 10000;
    }

    setVidLength(val);
    setRefinements({ ...refinements, vidLength: val });
    filterDispatch(
      allActions.filterActions.applyRefinements({
        ...refinements,
        vidLength: val,
      })
    );
  };

  const handleLikes = (e, n) => {
    let val = [];
    if (e == "min") {
      val[0] = n;
      val[1] = likesMax;
      setLikesMin(n);
    } else {
      val[0] = likesMin;
      val[1] = n;
      setLikesMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 0;
    }
    if (val[1] === undefined) {
      val[1] = 10000;
    }
    setLikes(val);
    setRefinements({ ...refinements, likes: val });
    filterDispatch(
      allActions.filterActions.applyRefinements({
        ...refinements,
        likes: val,
      })
    );
  };

  const handleComments = (e, n) => {
    let val = [];
    if (e == "min") {
      val[0] = n;
      val[1] = commentsMax;
      setCommentsMin(n);
    } else {
      val[0] = commentsMin;
      val[1] = n;
      setCommentsMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 0;
    }
    if (val[1] === undefined) {
      val[1] = 10000;
    }
    setComments(val);
    setRefinements({ ...refinements, comments: val });
    filterDispatch(
      allActions.filterActions.applyRefinements({
        ...refinements,
        comments: val,
      })
    );
  };
  const handleShare = (e, n) => {
    let val = [];
    if (e == "min") {
      val[0] = n;
      val[1] = sharesMax;
      setSharesMin(n);
    } else {
      val[0] = sharesMin;
      val[1] = n;
      setSharesMax(n);
    }
    if (val[0] === undefined) {
      val[0] = 0;
    }
    if (val[1] === undefined) {
      val[1] = 10000;
    }
    setShares(val);
    filterDispatch(
      allActions.filterActions.applyRefinements({
        ...refinements,
        shares: val,
      })
    );
  };

  // Handle Delete functions
  const handleCategoryDelete = (CATEGORY) => {
    let filteredValue = personName.filter((item) => item !== CATEGORY);
    setPersonName(filteredValue);
    setRefinements({ ...refinements, personName: filteredValue });
  };

  const handleAdTypeDelete = (ad) => {
    let filteredValue = adType.filter((item) => item !== ad);
    setAdType(filteredValue);
    setRefinements({ ...refinements, adType: filteredValue });
  };

  const handleEditTypeDelete = (ed) => {
    let filteredValue = editType.filter((item) => item !== ed);
    setEditType(filteredValue);
    setRefinements({ ...refinements, editType: filteredValue });
  };

  const handleClassificationDelete = (CLASSIFICATION) => {
    let filteredValue = Classification.filter(
      (item) => item !== CLASSIFICATION
    );
    setClassification(filteredValue);
    setRefinements({ ...refinements, Classification: filteredValue });
  };

  const handleVerifiedFbDelete = (VERIFIED_FB) => {
    // let filteredValue = verifiedFb.filter((item) => item !== VERIFIED_FB);
    setVerifiedFb("");
    setRefinements({ ...refinements, verifiedFb: "" });
  };

  const handleVerifiedInDelete = (VERIFIED_IN) => {
    setVerifiedIn("");
    setRefinements({ ...refinements, verifiedIn: "" });
  };

  const handleVideoLenDelete = (VID_LEN) => {
    // let filteredValue = vidLength.filter((item) => item !== VID_LEN);
    setVidLength([]);
    setRefinements({ ...refinements, vidLength: [] });
  };
  const handleDeletePricing = (VID_LEN) => {
    // let filteredValue = vidLength.filter((item) => item !== VID_LEN);
    setPricing([]);
    setPricingMax("");
    setPricingMin("");
    setRefinements({ ...refinements, pricing: [] });
  };

  const handleAdScoreDelete = (AD_SCORE) => {
    // let filteredValue = adScore.filter((item) => item !== AD_SCORE);
    setAdScore("");
    setRefinements({ ...refinements, adScore: "" });
    setAdScoreMax("");
    setAdScoreMin("");
    filterDispatch(
      allActions.filterActions.applyRefinements({
        personName: refinements.personName,
        Classification: refinements.Classification,
        adScore: [],
        verifiedFb: refinements.verifiedFb,
        verifiedIn: refinements.verifiedIn,
        vidLength: refinements.vidLength,
        likes: refinements.likes,
        comments: refinements.comments,
        shares: refinements.shares,
      })
    );
  };

  const handleCommentsDelete = (COMMENTS) => {
    // let filteredValue = comments.filter((item) => item !== COMMENTS);
    setComments([]);
    setRefinements({ ...refinements, comments: [] });
  };

  const handleSharesDelete = (SHARES) => {
    // let filteredValue = shares.filter((item) => item !== SHARES);
    setShares([]);
    setRefinements({ ...refinements, shares: [] });
  };

  const handleLikesDelete = (LIKES) => {
    // let filteredValue = likes.filter((item) => item !== LIKES);
    setLikes([]);
    setRefinements({ ...refinements, likes: [] });
  };

  const handleShowBlock = (e) => {
    document.getElementById(e).style = "display: flex";
  };

  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={"$" + value}
      >
        {children}
      </Tooltip>
    );
  }
  function ValueLabelComponentVideo(props) {
    const { children, open, value } = props;

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value + " secs"}
      >
        {children}
      </Tooltip>
    );
  }
  function ValueLabelComponentLikes(props) {
    const { children, open, value } = props;

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value + " Likes"}
      >
        {children}
      </Tooltip>
    );
  }
  function ValueLabelComponentComments(props) {
    const { children, open, value } = props;

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value + " Comments"}
      >
        {children}
      </Tooltip>
    );
  }
  function ValueLabelComponentShares(props) {
    const { children, open, value } = props;

    return (
      <Tooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={value + " Shares"}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <Container sm={12}>
      <Grid container spacing={4}>
        <Grid item sm={12} style={{ padding: 0 }}>
          <Grid
            item
            sm
            style={{ width: "100%", marginLeft: "0%", marginRight: "10%" }}
          >
            {adType?.map((ad) => {
              return (
                <Chip
                  style={styles.space}
                  label={ad}
                  onDelete={() => handleAdTypeDelete(ad, setAdType)}
                />
              );
            })}
            {editType?.map((ed) => {
              return (
                <Chip
                  style={styles.space}
                  label={ed}
                  onDelete={() => handleEditTypeDelete(ed, setEditType)}
                />
              );
            })}
            {personName?.map((category) => {
              return (
                <Chip
                  style={styles.space}
                  label={category}
                  onDelete={() => handleCategoryDelete(category, setPersonName)}
                />
              );
            })}
            {Classification.map((e) => (
              <Chip
                style={styles.space}
                label={e}
                onDelete={() => handleClassificationDelete(e)}
              />
            ))}

            {verifiedFb && (
              <Chip
                style={styles.space}
                label={"Facebook verified " + verifiedFb}
                onDelete={() => handleVerifiedFbDelete()}
              />
            )}

            {verifiedIn && (
              <Chip
                style={styles.space}
                label={"Instagram verified " + verifiedIn}
                onDelete={() => handleVerifiedInDelete()}
              />
            )}
            {!!vidLength && !!vidLength.length && (
              <Chip
                style={styles.space}
                label={"Video length " + vidLength[0] + "-" + vidLength[1]}
                onDelete={() => handleVideoLenDelete()}
              />
            )}
            {!!pricing && !!pricing.length && (
              <Chip
                style={styles.space}
                label={"Pricing" + "$" + pricing[0] + "-$" + pricing[1]}
                onDelete={() => handleDeletePricing()}
              />
            )}
            {comments && !!comments.length && (
              <Chip
                style={styles.space}
                label={"Comments " + comments[0] + "-" + comments[1]}
                onDelete={() => handleCommentsDelete()}
              />
            )}
            {shares && !!shares.length && (
              <Chip
                style={styles.space}
                label={"Shares " + shares[0] + "-" + shares[1]}
                onDelete={() => handleSharesDelete()}
              />
            )}
            {likes && !!likes.length && (
              <Chip
                style={styles.space}
                label={"Likes " + likes[0] + "-" + likes[1]}
                onDelete={() => handleLikesDelete()}
              />
            )}
            {adScore && !!adScore.length && (
              <Chip
                style={styles.space}
                label={"Ad Score " + adScore[0] + "-" + adScore[1]}
                onDelete={() => handleAdScoreDelete()}
              />
            )}
          </Grid>
        </Grid>
        <Grid item sm={4}>
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel id="demo-mutiple-chip-label">Categories 🌎</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip9"
              multiple
              value={personName}
              onChange={handleChange}
              input={<Input id="select-multiple-chip1" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {ctegories.map((name) => (
                <MenuItem
                  key={Math.random()}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          {/* <FormControl className={classes.formControl} style={styles.full}>
            <div onClick={() => handleShowBlock("pricing-slider")}>
              <InputLabel className="border disable-label">
                Pricing 💰
              </InputLabel>
            </div>
          </FormControl>
          <Paper
            className="card-custom"
            style={styles.full}
            id="pricing-slider"
            ref={pricingRef}
          >
            <Slider
              ValueLabelComponent={ValueLabelComponent}
              onChangeCommitted={handlePricing}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={(e) => e}
              defaultValue={[1, 10000]}
              min={1}
              max={10000}

            />
          </Paper> */}

          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              shrink={true}
              variant="standard"
              id="demo-mutiple-chip-label"
            >
              {" "}
              Pricing 💰 (Dollars)
            </InputLabel>
            <div style={styles.row}>
              <Input
                value={pricingMin}
                onChange={(e) => handlePricing("min", e.target.value)}
                type="number"
                min="0"
                max="9999"
                style={styles.input}
                placeholder="Min"
                inputProps={{ "aria-label": "min" }}
              />
              <Input
                value={pricingMax}
                onChange={(e) => handlePricing("max", e.target.value)}
                type="number"
                max="10000"
                style={{ ...styles.input, marginLeft: 20 }}
                placeholder="Max"
                inputProps={{ "aria-label": "max" }}
              />
            </div>
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel id="demo-mutiple-chip-label">Ad type 🖼 </InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip9"
              multiple
              value={adType}
              onChange={handleAdType}
              input={<Input id="select-multiple-chip1" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {adTypes.map((name) => (
                <MenuItem
                  key={Math.random()}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel id="demo-mutiple-chip-label">
              {" "}
              Types of Editing 📷{" "}
            </InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip9"
              multiple
              value={editType}
              onChange={handleEditType}
              input={<Input id="select-multiple-chip1" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {editTypes.map((name) => (
                <MenuItem
                  key={Math.random()}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel id="demo-mutiple-chip-label">
              Classifications 🦄
            </InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip1"
              multiple
              value={Classification}
              onChange={handleChangeClass}
              input={<Input id="select-multiple-chi2p" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {ClassificationCat.map((name) => (
                <MenuItem
                  key={Math.random()}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          {/* <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              onClick={() => handleShowBlock("bloc-slider")}
              className="border disable-label"
            >
              Ad Score 🔥
            </InputLabel>
          </FormControl>
          <Paper
            className="card-custom"
            style={styles.full}
            id="bloc-slider"
            ref={scoreRef}
          >
            <Slider
              onChangeCommitted={handleChangeScore}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              getAriaValueText={(e) => e}
              defaultValue={[6, 10]}
              min={6}
              max={10}
            />
          </Paper> */}
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              shrink={true}
              variant="standard"
              id="demo-mutiple-chip-label"
            >
              Ad Score 🔥 (0-10)
            </InputLabel>
            <div style={styles.row}>
              <Input
                value={adSocreMin}
                onChange={(e) => handleChangeScore("min", e.target.value)}
                type="number"
                min="0"
                max="9999"
                style={styles.input}
                placeholder="Min"
                inputProps={{ "aria-label": "min" }}
              />
              <Input
                value={adSocreMax}
                onChange={(e) => handleChangeScore("max", e.target.value)}
                type="number"
                max="10000"
                style={{ ...styles.input, marginLeft: 20 }}
                placeholder="Max"
                inputProps={{ "aria-label": "max" }}
              />
            </div>
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel id="demo-mutiple-chip-label">Verified ✔</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={""}
              onChange={handelFb}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item sm={4} >
          <FormControl  className={classes.formControl} style={styles.full}  >
            <InputLabel id="demo-mutiple-chip-label">
              Verified Instagrams
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={""}
              onChange={handleIn}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item sm={4}>
          {/* <FormControl className={classes.formControl} style={styles.full}>
            <div
              className="border"
              onClick={() => handleShowBlock("vide-block")}
            >
              <InputLabel className="disable-label">Video Length ⏱</InputLabel>
            </div>
          </FormControl>
          <Paper
            className="card-custom"
            style={styles.full}
            id="vide-block"
            ref={videRef}
          >
            <Slider
              classes={{ thumb: "thumb", valueLabel: "label-slider" }}
              onChangeCommitted={handleVid}
              valueLabelDisplay="auto"
              ValueLabelComponent={ValueLabelComponentVideo}
              aria-labelledby="range-slider"
              getAriaValueText={(e) => e}
              defaultValue={[0, 5000]}
              min={0}
              max={5000}
            />
          </Paper> */}
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              shrink={true}
              variant="standard"
              id="demo-mutiple-chip-label"
            >
              Video Length ⏱ (Seconds)
            </InputLabel>
            <div style={styles.row}>
              <Input
                value={vidLengthMin}
                onChange={(e) => handleVid("min", e.target.value)}
                type="number"
                min="0"
                max="9999"
                style={styles.input}
                placeholder="Min"
                inputProps={{ "aria-label": "min" }}
              />
              <Input
                value={vidLengthMax}
                onChange={(e) => handleVid("max", e.target.value)}
                type="number"
                max="10000"
                style={{ ...styles.input, marginLeft: 20 }}
                placeholder="Max"
                inputProps={{ "aria-label": "max" }}
              />
            </div>
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          {/* <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              onClick={() => handleShowBlock("likes-block")}
              className="border disable-label"
            >
              Likes 👍
            </InputLabel>
          </FormControl>
          <Paper
            className="card-custom"
            style={styles.full}
            id="likes-block"
            ref={likesRef}
          >
            <Slider
              classes={{ thumb: "thumb", valueLabel: "label-slider" }}
              onChangeCommitted={handleLikes}
              ValueLabelComponent={ValueLabelComponentLikes}
              aria-labelledby="range-slider"
              min={0}
              max={45500}
              getAriaValueText={(e) => e}
              defaultValue={[0, 455000]}
            />
          </Paper> */}

          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              shrink={true}
              variant="standard"
              id="demo-mutiple-chip-label"
            >
              Likes 👍
            </InputLabel>
            <div style={styles.row}>
              <Input
                value={likesMin}
                onChange={(e) => handleLikes("min", e.target.value)}
                type="number"
                min="0"
                max="9999"
                style={styles.input}
                placeholder="Min"
                inputProps={{ "aria-label": "min" }}
              />
              <Input
                value={likesMax}
                onChange={(e) => handleLikes("max", e.target.value)}
                type="number"
                max="10000"
                style={{ ...styles.input, marginLeft: 20 }}
                placeholder="Max"
                inputProps={{ "aria-label": "max" }}
              />
            </div>
          </FormControl>
        </Grid>

        <Grid item sm={4}>
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              shrink={true}
              variant="standard"
              id="demo-mutiple-chip-label"
            >
              Comments 🗯
            </InputLabel>
            <div style={styles.row}>
              <Input
                value={commentsMin}
                onChange={(e) => handleComments("min", e.target.value)}
                type="number"
                min="0"
                max="9999"
                style={styles.input}
                placeholder="Min"
                inputProps={{ "aria-label": "min" }}
              />
              <Input
                value={commentsMax}
                onChange={(e) => handleComments("max", e.target.value)}
                type="number"
                max="10000"
                style={{ ...styles.input, marginLeft: 20 }}
                placeholder="Max"
                inputProps={{ "aria-label": "max" }}
              />
            </div>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              shrink={true}
              variant="standard"
              id="demo-mutiple-chip-label"
            >
              Date 📅
            </InputLabel>
            <div style={styles.row}>
              <button
                onClick={() => setShowCalender((e) => !e)}
                className="date-range"
              >
                Start date
              </button>
              <button
                className="date-range"
                style={{ marginLeft: 20 }}
                onClick={() => setShowCalender((e) => !e)}
              >
                End date
              </button>
            </div>
            {showCalender && (
              <DateRangePicker
                firstOfWeek={1}
                numberOfCalendars={2}
                selectionType="range"
                showLegend={true}
                onSelect={handleDateRange}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          {/* <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              onClick={() => handleShowBlock("shares-block")}
              className="border disable-label"
            >
              Shares ✉{" "}
            </InputLabel>
          </FormControl>
          <Paper
            className="card-custom"
            style={styles.full}
            id="shares-block"
            ref={sharesRef}
          >
            <Slider
              classes={{ thumb: "thumb", valueLabel: "label-slider" }}
              min={0}
              max={50000}
              onChangeCommitted={handleShare}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              ValueLabelComponent={ValueLabelComponentShares}
              getAriaValueText={(e) => e}
              defaultValue={[10, 100000]}
            />
          </Paper> */}
          <FormControl className={classes.formControl} style={styles.full}>
            <InputLabel
              shrink={true}
              variant="standard"
              id="demo-mutiple-chip-label"
            >
              Shares ✉
            </InputLabel>
            <div style={styles.row}>
              <Input
                value={sharesMin}
                onChange={(e) => handleShare("min", e.target.value)}
                type="number"
                min="0"
                max="9999"
                style={styles.input}
                placeholder="Min"
                inputProps={{ "aria-label": "min" }}
              />
              <Input
                value={sharesMax}
                onChange={(e) => handleShare("max", e.target.value)}
                type="number"
                max="10000"
                style={{ ...styles.input, marginLeft: 20 }}
                placeholder="Max"
                inputProps={{ "aria-label": "max" }}
              />
            </div>
          </FormControl>
        </Grid>

        <Grid item sm={12} id="stats" className="filter-stats-info"></Grid>
      </Grid>
    </Container>
  );
}

// // Map Dispatch to props
// const mapDispatchToProps = (dispatch) => {
//   // Attach these to props
//   return {
//     // Take refinements and pass to applyRefinements action creator
//     applyRefinements: (refinements) => dispatch(applyRefinements(refinements)),
//   };
// };

// export default connect(null, mapDispatchToProps)(FilterBay);
export default FilterBay;

const styles = {
  select: { width: "100%", marginTop: 20 },
  full: { width: "125%" },
  space: { marginRight: 10 },
  textfull: { width: "100%" },
  row: { display: "flex", flexDirection: "row", marginTop: 16 },
  half: {
    width: "100%",
    marginLeft: 20,
    alignItems: "center",
    marginTop: -32,
  },
  minMaxCOntainer: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
  },
  input: {},
};

const ctegories = [
  "Personal Items",
  "Beauty",
  "Underwear",
  "Homeware",
  "Clothing",
  "Food & Drinks",
  "Accessories",
  "Unique Stores",
  "Electronics",
  "Footwear",
  "Jewelry",
  "Fitness",
  "Children",
  "Pets",
  "Female Clothing",
  "Car & Vehicles",
  "Sports",
  "Outdoors",
  "Male Clothing",
  "Swimwear",
  "Health & Care",
];

const editTypes = ["Raw Cuts", "Highly Edited"];
const adTypes = [
  "Social Proof",
  "Product Demo",
  "Lifestyle",
  "Unboxing",
  "Spokesperson Anchor",
  "Dynamic Ad",
  "Case Study",
];
const ClassificationCat = [
  "Smart Money",
  "Dumb Money",
  "Influencer Page",
  "Smart Money,Influencer Page",
  "Dumb Money,Influencer Page",
];

const adScoreCat = ["10/10", "9/10", "8/10", "6/10", "7/10"];

const vFb = ["No", "Yes"];

const vIn = ["No", "Yes"];

const vLength = [
  "0s - 5s",
  "6s - 15s",
  "16s - 30s",
  "31s - 60s",
  "61s - 120s",
  "121s - 180s",
  "180s+",
  "All",
];

const likesCat = [
  "0 - 25",
  "25 - 100",
  "101 - 250",
  "251 - 500",
  "501 - 1000",
  "1001 - 5000",
  "5001 - 20,000",
  "20,001 - 50,000",
  "50,000+",
  "All",
];
const commentsCat = [
  "0 - 25",
  "25 - 100",
  "101 - 250",
  "251 - 500",
  "501 - 1000",
  "1001 - 5000",
  "5001 - 20,000",
  "20,001 - 50,000",
  "50,000+",
  "All",
];

const sharesCat = [
  "0 - 25",
  "25 - 100",
  "101 - 250",
  "251 - 500",
  "501 - 1000",
  "1001 - 5000",
  "5001 - 20,000",
  "20,001 - 50,000",
  "50,000+",
  "All",
];
