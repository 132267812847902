// // Filter Reducer
// const filterReducer = (state = {}, action) => {
//   // Take Actions
//   switch (action.type) {
//     // APPLY_REFINEMENTS
//     case "APPLY_REFINEMENTS":
//       console.log("applied refinements", action.payload);

//       return { ...state, refinements: action.payload };

//     default:
//       return state;
//   }
// };

// export default filterReducer;

const filterReducer = (state = {}, action) => {
  switch (action.type) {
    case "APPLY_REFINEMENTS":
      return {
        ...state,
        refinements: action.payload,
      };

    default:
      return state;
  }
};

export default filterReducer;
