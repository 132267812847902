import React from "react";
import { Grid, Typography, Slider, Box } from "@material-ui/core";
// import Paper from "@material-ui/core";
import GaugeChart from "react-gauge-chart";
import { useEffect } from "react";

export default function InfoCard({
  num,
  title,
  search,
  subtitle,
  type,
  data,
  index,
  secondrow,
  totalResults,
  filterName
}) {
  const total = 10000;
  let diff = (num / total) * 100;

  let titleText;

  if (title === "Primary text word count") {
    titleText = "Ideal Primary Text Length";
  } else if (title === "Headline word count") {
    titleText = "Ideal Headline Length";
  } else if (title === "Text Score Flesch Kincaid") {
    titleText = "Ideal Grade Level Reading For Copy";
  } else if (title === "Ideal Video Creative Length") {
    titleText = "Ideal Video Creative Length";
  } else if (title === "Product Price") {
    titleText = " Average Product Price";
  }

  const width = secondrow ? "44%" : "80%";

  return (
    <div >
      <Grid
        container
        spacing={3}
        style={
          type === "number" ?
            {
              backgroundColor: "#ffff",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              borderRadius: "10px",
              padding: "0px",
              width: 300,
              height: 250,
            } :
            {
              backgroundColor: 'white',
              padding: 0,
              borderRadius: "10px",
            }
        }
        className={type !== "number" && "card-middle-grid"}
      >
        <Grid item md={12} sm={12} xs={12}
          className={"second-mid"} >
          <div
            style={
              type === "number"
                ? {
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",


                }
                : {
                  justifyContent: "center",
                  alignItems: 'center',
                  textAlign: 'center',
                  height: '100%'
                }
            }
          >
            {/* {type === "number" && (
              <div>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {titleText}
                </Typography>
              </div>
            )} */}

            {/* furst card */}
            {type !== "number" && (
              <div
                style={{
                  height: '100%', display: 'flex', flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <div
                    style={{
                      backgroundColor: '#5874a7',
                      width: '100%',
                      height: 5
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: '#4f6794',
                      width: '100%',
                      height: 5
                    }}
                  />
                </div>
                <div>
                  <p style={{ color: '#5874a7', fontWeight: 'bold', fontSize: 24 }}> {subtitle} Top Ads For {search} {filterName}</p>
                </div>
                <div style={{
                  backgroundColor: '#5874a7',
                  height: '35%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <div className="curcle">
                    <span>
                      {secondrow
                        ? (index === 16 ? "$" : "") +
                        Math.round(num / totalResults)
                        : num}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* {type === "number" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{ minWidth: "40%" }}
                  className={`${type === "number" ? "analytics-circle" : ""}`}
                >
                  <Box
                    className={`${type === "number" ? "analytics-circle-inner" : ""
                      }`}
                  >
                    <Typography
                      style={{
                        fontSize: 26,
                        fontWeight: "bold",
                      }}
                    >
                      {secondrow
                        ? (index === 16 ? "$" : "") +
                        Math.round(num / totalResults)
                        : num}
                    </Typography>
                  </Box>
                </Box>
                {type === "number" && (
                  <Typography
                    style={{
                      color: "grey",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                    {index === 15
                      ? "seconds"
                      : index === 16
                        ? "Dollars"
                        : index === 3
                          ? "Grade"
                          : "Words"}
                  </Typography>
                )}
              </div>
            )} */}
            {type === "number" && (
              <div
                style={{
                  height: '100%', display: 'flex', flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <div
                    style={{
                      backgroundColor: '#5874a7',
                      width: '100%',
                      height: 5
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: '#4f6794',
                      width: '100%',
                      height: 5
                    }}
                  />
                </div>
                <div>
                  <p style={{ color: '#5874a7', fontWeight: 'bold', fontSize: 20, textAlign: 'center', marginBottom: 30 }}>
                    {titleText}
                  </p>
                </div>
                <div style={{
                  backgroundColor: '#5874a7',
                  height: '35%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <div style={{

                  }}>
                    <div className="curcle" style={{justifyContent: 'center', textAlign: 'center'}}>
                      <span>
                        {secondrow
                          ? (index === 16 ? "$" : '') +
                          Math.round(num / totalResults)
                          : num}{index == 3 && "th"}
                          <br />
                        <p style={{fontSize: 11,padding: 0, margin: 0}}>
                          {index === 15
                            ? "seconds"
                            : index === 16
                              ? "Dollars"
                              : index === 3
                                ? "Grade"
                                : "Words"}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {type !== "number" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {/* total ads here */}
                {/* {secondrow
                        ? (index === 16 ? "$" : "") +
                        Math.round(num / totalResults)
                        : num} */}
                {type === "number" && (
                  <Typography
                    style={{
                      color: "grey",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                    {index === 15
                      ? "seconds"
                      : index === 16
                        ? "Dollars"
                        : index === 3
                          ? "Grade"
                          : "Words"}
                  </Typography>
                )}
              </div>
              // <GaugeChart
              //   id="gauge-chart2"
              //   textColor="black"
              //   nrOfLevels={10}
              //   percent={diff}
              //   hideText={true}
              //   colors={["#FFC371", "#FF5F6D"]}
              // />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
