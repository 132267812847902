import React, { useEffect } from "react";
import "./App.css";
import { Layout } from "./Layouts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Modules/Home/Home";
import { Login } from "./Modules/Authentication/Login";
import { Signup } from "./Modules/Authentication/Signup";
import Search from "./Modules/Search/Search";
import Favourite from "./Modules/Favourite/Favourite";
import Analytics from "./Modules/Analytics";
import { Admin } from "./Modules/Admin";
import { RevenueGenerator } from "./Modules/RevenueGenerator";
import { useStateValue } from "./Providers/UserProvider";
import University from './Modules/Universtiy';
import AdComparison from './Modules/AdComparison';
import ChangePassword from './Modules/Authentication/ChangePassword';

function App() {
  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    // console.log(user)

    const token = localStorage.getItem("token");
    if (token) {
      dispatch({
        type: "SET_USER",
        user: { token: token, data: { _id: localStorage.getItem("userId") } },
      });
    } else {
      dispatch({
        type: "SET_USER",
        user: null,
      });
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/layout" element={user ? <Layout><Search /></Layout> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/resetpassword" element={<ChangePassword />} />
        <Route path="/admin" element={user ? <Admin /> : <Login />} />
        <Route path="/search" element={<Search />} />
        <Route path="/favourite" element={<Favourite />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/AdComparison" element={user ? <Layout><AdComparison /></Layout> : <Login />} />
        <Route path="/University" element={user ? <Layout><University /></Layout> : <Login />} />
        <Route path="/revenue" element={user ? <Layout><RevenueGenerator /></Layout> : <Login />} />
      </Routes>
    </Router>
  );
}

export default App;
