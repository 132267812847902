import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles, TextField, CircularProgress } from "@material-ui/core";
import Logo from "../../Assets/Images/logoBlack.png";
import { Navbar } from "../../Components";
import { useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from 'axios';
import { api } from '../../Config/api';
import { setLocale } from "faker";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        letterSpacing: "10px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5rem",
        width: "30%",
        backgroundColor: "#fff",
        padding: "30px",
        borderRadius: "20px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
        textAlign: "center",

        [theme.breakpoints.down("md")]: {
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[7],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "50%",
        textAlign: "center",
        border: "none",
        outline: "none",
        borderRadius: "20px",
        padding: theme.spacing(2, 4, 3),
    },
}));


export default function ChangePassword() {
    const history = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [label, setLabel] = useState('');
    const [keys, setKeys] = useState();
    const [confirm, setConfirm] = useState('');

    const handleClose = () => {
        setModalOpen(!modalOpen);
        history('/login')

    };

    useEffect(() => {
        const url = global.location.href;
        if (url) {
            const params = (new URL(url)).searchParams;
            // console.log(params.get('id'));
            const id = params.get('u');
            const key = params.get('k');
            setKeys({
                id,
                key
            })
        }
    }, [])

    const reset = () => {

        if (keys.id && keys.key) {
            if (password && password === confirm) {
                setIsLoading(true);
                axios
                    .post(api + "/changePassword/" + keys.id + '/' + keys.key, {
                        password: password,
                        confirm_password: confirm
                    })
                    .then((success) => {
                        console.log(success);
                        setIsLoading(false)
                        if (success.data?.msg === "Password has changed successfully") {
                            setModalOpen(true);
                        }
                    }).catch((e) => {
                        setIsLoading(false)
                        console.log(e.response)
                        if (e.response?.data?.msg == "Invalid email address") {
                            setLabel("invalid email");
                        }
                    })
            } else if (password != confirm) {
                setIsLoading(false)
                alert("password does not match")
            }
        } else {
            alert("please try again");
        }
    };

    return (
        <div className={classes.root}>
            <Navbar />
            <div className={classes.form}>
                <div>
                    <img src={Logo} alt="LOGO" width="100px" />
                </div>
                <Grid container spacing={3}>
                    <Grid item md={12} sm={12} xs={12}>
                        <h1 style={{ color: "#3F4D67" }}>Reset Password</h1>
                    </Grid>


                    <Grid item md={12} sm={12} xs={12}>
                        <TextField
                            type="password"
                            id="standard-basic2"
                            label="Password"
                            fullWidth
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <TextField
                            type="password"
                            id="standard-basic2"
                            label="Confirm Password"
                            fullWidth
                            onChange={(e) => setConfirm(e.target.value)}
                            value={confirm}
                        />
                    </Grid>

                    <br />
                    <p style={{ textAlign: "center", color: "red" }}>{label}</p>
                    <br />
                    <Grid item md={12} sm={12} xs={12}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#3F4D67", color: "white" }}
                            fullWidth
                            onClick={reset}
                        >
                            Reset
                {isLoading && <CircularProgress color="#fff" size={20} style={{ marginLeft: '5px' }} />}
                        </Button>
                    </Grid>

                </Grid>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <div className={classes.paper}>
                        <img
                            className="entryPortalLogo"
                            src={Logo}
                            href="/"
                            alt="Brain Logo"
                            width="200px"
                        />
                        <Grid item md={12} sm={12} xs={12}>
                            <h3 style={{ color: "#3F4D67" }}>
                                Password has changed successfully
                </h3>
                        </Grid>

                        <br />
                        <br />
                        <br />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                                style={{ backgroundColor: "#3F4D67", color: "white" }}
                            >
                                Close
                </Button>

                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}