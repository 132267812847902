/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  CircularProgress,
  Button,
  Badge,
} from "@material-ui/core";
import { Post } from "./Post";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useStateValue } from "../../Providers/UserProvider";
import SearchIcon from "@material-ui/icons/Search";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterBay from "./filter";
import { api } from "../../Config/api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import allActions from "../../store/actions";
import CornerRibbon from "react-corner-ribbon";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  searchbar: {
    width: "80%",
    padding: "16px 40px 16px 40px",
    border: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
    borderRadius: "50px",
    outline: "none",
    fontSize: "16px",
  },
  form: {
    width: "100%",
  },
});
const Search = () => {
  const [{ user }] = useStateValue();
  const classes = useStyles();
  const [state, setState] = useState({
    cardData: [],
    current: [],
    filteredList: [],
    hasMore: false,
    filter: false,
    skip: 0,
    limit: 10,
  });
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(false);
  const [expnad, setExpand] = useState(false);
  const selectedData = useSelector((state) => state.filterReducer);
  const filterDispatch = useDispatch();
  const [isRemoved, setRemoved] = useState(false);

  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });

  const getMoreData = () => {
    setState({ ...state, hasMore: true });
    if (state.current.length === state.cardData.length) {
      setState({ ...state, hasMore: false });
      return;
    }
    setTimeout(() => {
      let a = state.current.concat(
        state.cardData.slice(count.prev + 10, count.next + 10)
      );
      setState({ ...state, current: a, hasMore: false });
    }, 2000);
    setCount((prevState) => ({
      ...prevState,
      prev: prevState.prev + 10,
      next: prevState.next + 10,
    }));
  };

  const clearRefimenet = () => {
    console.log("clearing refines ==== >");
    filterDispatch(
      allActions.filterActions.applyRefinements({
        personName: [],
        Classification: [],
        adScore: [],
        verifiedFb: [],
        verifiedIn: [],
        vidLength: [],
        likes: [],
        comments: [],
        shares: [],
      })
    );
  };



  useEffect(() => {
    let timer;
    let refine;
    const filters = selectedData?.refinements;
    if (
      (filters?.Classification && filters?.Classification?.length) ||
      filters?.adScore?.length ||
      filters?.comments?.length ||
      filters?.likes?.length ||
      filters?.personName?.length ||
      filters?.shares?.length ||
      filters?.verifiedFb ||
      filters?.vidLength?.length ||
      filters?.pricing?.length || 
      filters?.editType?.length ||
      filters?.adType?.length 
    ) {
      timer = setTimeout(() => {
        getFilteredCards();
      }, 1000);
    } else {
      console.log("Ge5t cards");
      getCards(state.skip, state.limit);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [color, selectedData, selectedData?.refinements, isRemoved]);

  const getFilteredCards = async () => {
    console.log("Selected", selectedData);
    setLoading(true);
    const headers = { token: user.token };
    const data = {
      category: selectedData.refinements.personName, // array of string
      classification: selectedData.refinements.Classification, // array of string ,

      adTypes: selectedData?.refinements?.adType,
      ad_score: selectedData?.refinements?.adScore?.length ? `${selectedData.refinements?.adScore[0] + "/" +selectedData.refinements?.adScore[1]}` :'',
      verified_facebook: selectedData.refinements.verifiedFb === "No" ? true : false ,
      verified_instagram: selectedData.refinements.verifiedFb === "No" ? true : false ,

      video_duration: !!selectedData.refinements.vidLength.length && {
        range1: selectedData.refinements.vidLength[0],
        range2: selectedData.refinements.vidLength[1],
      },
      likes: selectedData?.refinements?.likes?.length
        ? {
          range1: selectedData.refinements.likes[0],
          range2: selectedData.refinements.likes[1],
        }
        : { range1: 0, range2: 50000 },
      comments: selectedData?.refinements?.comments?.length
        ? {
          range1: selectedData?.refinements?.comments[0],
          range2: selectedData.refinements.comments[1],
        }
        : { range1: 0, range2: 50000 },
      shares: selectedData?.refinements?.shares?.length
        ? {
          range1: selectedData?.refinements?.shares[0],
          range2: selectedData?.refinements?.shares[1],
        }
        : { range1: 0, range2: 50000 },
        Averaged_Out_Pricing: selectedData?.refinements?.pricing?.length
        ? {
          range1:selectedData?.refinements?.pricing[0] ,
          range2: selectedData.refinements.pricing[1],
        }
        : '',
        editTypes: selectedData?.refinements?.editType,
        keyword: search
    };

    console.log("added", data);
    const response = await axios.post(
      `https://projectb2.herokuapp.com/filterSearch?skip=${0}&limit=${10}`,
      // `http://localhost:4000/filterSearch?skip=${0}&limit=${10}`,
      data,
      { headers }
    );
    // console.log(response);
    if (response.data?.length && response.status == 200) {
      // setState({ ...state, cardData: [] })
      setState({ ...state, cardData: response.data });
      setLoading(false);
    } else if (!response.data?.length) {
      setState({ ...state, cardData: response.data });
      setLoading(false);
    }
  };

  const getCards = async (skip, limit) => {
    const headers = { token: user.token };
    const response = await axios.get(
      `https://projectb2.herokuapp.com/get?skip=${skip}&limit=${limit}`,
      { headers }
    );
    setState({
      ...state,
      cardData: state.cardData.concat(response.data.result),
      skip: state.skip + 15,
    });
    console.log(response.data);
    const res = await axios.post(
      `https://projectb2.herokuapp.com/getAllFavourites?skip=${skip}&limit=${limit}`,
      { user: user?.data?._id },
      { headers }
    );
    if (res.data?.msg === "Favourites Found Successfully") {
      const ids = res.data.result;
      let filteredId = [];
      ids.forEach((element, i) => {
        if (element._id) {
          filteredId.push(element._id);
        }
      });
      let favs = [...response.data.result];
      response?.data.result.forEach((e, i) => {
        filteredId.forEach((j) => {
          if (e._id === j) {
            favs[i].fav = true;
          }
        });
      });
      setState({
        ...state,
        cardData: state.cardData.concat(favs),
        skip: state.skip + 15,
      });
    }
  };

  const showMore = () => {
    getCards(state.skip, state.limit);
  };

  const addToFav = async (id, i) => {
    const headers = { token: user?.token };
    console.log("Adding fav", headers)
    if (user?.data) {
      const res = await axios.post(
        api + "/addFavourite",
        {
          user: user.data._id,
          favourites: { _id: id },
        },
        { headers }
      );
      // console.log(res.response)
      if (res.data.msg === "Favourite add Successfully") {
        let index = state.cardData.findIndex((s) => s._id === id);
        if (index || index === 0) {
          let ids = [...state.cardData];
          ids[index].fav = true;

          setState({ ...state, cardData: ids });
        }
        setColor((e) => !e);
      }
    } else {
      alert("sign in again");
    }
  };

  const removeFav = async (id) => {
    // if (!user?.token) history.push("/");
    const headers = { token: user?.token };
    const response = await axios.post(
      `https://projectb2.herokuapp.com/removeFavourite`,
      { user: user?.data?._id, unFavourite: { _id: id } },
      { headers }
    );
    if (response.data?.msg === "Favourite removed Successfully") {
      let index = state.cardData.findIndex((s) => s._id === id);
      if (index || index === 0) {
        let ids = [...state.cardData];
        ids[index].fav = false;

        setState({ ...state, cardData: ids });
      }
      setColor((e) => !e);

    }
  };

  const filtered = (e) => {
    setSearch(e.target.value);
  };

  const hadnlekeydown = (e) => {
    if (e.key === "Enter") {
      // handleSearch();
      getFilteredCards()
    }
  };

  const handleSearch = async () => {
    if (search) {
      setLoading(true);

      // Get token

      const headers = { token: user?.token };
      console.log(headers);
      const response = await axios.post(
        `https://projectb2.herokuapp.com/search?limit=20&skip=0`,
        // `http://localhost:4000/search?limit=20&skip=0`,
        { keyword: search },
        { headers }
      );

      if (response?.data?.length) {
        console.log(response.data);
        setState({ ...state, cardData: [] });
        setState({ ...state, cardData: response.data });

        const res = await axios.post(
          `https://projectb2.herokuapp.com/getAllFavourites?skip=${state.skip}&limit=${state.limit}`,
          { user: user?.data?._id },
          { headers }
        );
        if (res.data?.msg === "Favourites Found Successfully") {
          const ids = res.data.result;
          let filteredId = [];
          ids.forEach((element, i) => {
            if (element._id) {
              filteredId.push(element._id);
            }
          });
          let favs = [...response.data];
          response?.data.forEach((e, i) => {
            filteredId.forEach((j) => {
              if (e._id === j) {
                favs[i].fav = true;
              }
            });
          });
          setState({ ...state, cardData: favs });
        }
      } else {
        alert("no result found");
      }
      setLoading(false);
    }
  };

  // USE SELECTOR

  // REDUX
  // useEffect(() => {
  //   console.log(refinements);
  // }, [refinements]);

  // // REDUX

  const post =
    !!state.cardData.length &&
    state.cardData.map((data, index) => {
      // Filter Data
      // console.log('data', data.top_percentage_ad)
      let top5 = data?.top_percentage_ad;
      let videolink = data?.video_links_dropbox;
      // console.log(videolink);
      return (
        <div
          item
          key={index}
          className="card-container2"
        >
          <Post
            key={index}
            addToFav={addToFav}
            removeFav={removeFav}
            fav={data?.fav}
            profile_picture={data.profile_pictures}
            id={data._id}
            page_name={data.page_name}
            primary_text={data.primary_text}
            date_posted={data.date_posted}
            fb_video_ad_link={data.fb_video_ad_link}
            objectID={data.objectID}
            text={data.Category}
            video_links_dropbox={videolink}
            headline={data.headline}
            description={data.description}
            commentss={data.commentss}
            shares_={data.shares_}
            post_reactions={data.post_reactions}
            outbound_URL={data.outbound_URL}
            Main_Ad_Type={data.Main_Ad_Type}
            Secondary_Ad_Type={data.Secondary_Ad_Type_if_it_exists}
            Revenue_Generated={data.Revenue_Generated$}
            Price_Generated={data.Averaged_Out_Pricing}
            Top_Ad={data.top_percentage_ad}
          />
        </div>
      );
    });

  return (
    <div className={classes.root}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          className={classes.searchbar}
          onChange={(e) => filtered(e)}
          type="search"
          placeholder="Search Here...."
          value={search}
          id="form"
          onKeyDown={hadnlekeydown}
        />
        <SearchIcon
          onClick={getFilteredCards}
          style={{
            backgroundColor: "#3f4d67",
            marginLeft: 10,
            width: 40,
            height: 40,
            padding: 5,
            borderRadius: 30,
          }}
          color="secondary"
        />
      </div>

      <Accordion
        style={{
          background: "transparent",
          border: "none",
          boxShadow: "none",
          marginTop: "20px",
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Button
            onClick={() => setExpand((e) => !e)}
            style={{ margin: "0 auto" }}
          >
            {expnad ? "-" : "+"} Additional Filters
          </Button>
        </AccordionSummary>

        <AccordionDetails>
          <FilterBay />
        </AccordionDetails>
      </Accordion>

      {loading && <CircularProgress size={18} />}
      <div>
        <InfiniteScroll
          dataLength={state.current.length}
          next={getMoreData}
          hasMore={state.hasMore}
        >
          <div className="main_contianer"
          container spacing={0} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            {post}
          </div>
          <Grid container justify={"center"} style={{ padding: 20 }}>
            {!!state.cardData.length && (
              <Button
                style={{ width: 150, height: 40 }}
                onClick={() => showMore()}
              >
                {state.hasMore ? <CircularProgress size={18} /> : "Show More"}
              </Button>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Search;
