import React, { useEffect, useState } from "react";
import {
  Button,
  createMuiTheme,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useStateValue } from "../../Providers/UserProvider";
import { Navbar } from "../../Components/Navbar";
import { Login } from "../Authentication/Login";
import axios from "axios";

const useStyles = makeStyles({
  table: {
    width: "100%",
    textAlign: "center",
    marginTop: 250
  },
  adminTable: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
const TableTheme = createMuiTheme({
  overrides: {
    MuiTableContainer: {
      root: {
        width: "90%",
      },
    },
  },
});

export const Admin = () => {
  const [{ user }] = useStateValue();
  const [users, setUsers] = useState();
  const [userActive, setUserActive] = useState(false);
  const classes = useStyles();

  async function getUsers() {
    if (user?.token) {
      const headers = { token: user.token };
      const response = await axios.get(
        `https://projectb2.herokuapp.com/getAllUsers`,
        { headers }
      );
      console.log(response.data);
      setUsers(response.data?.users);
    }
  }

  useEffect(() => {
    getUsers();
  }, [userActive]);

  const handleActivate =async (id) => {
    console.log(id)
    const headers = { token: user.token };
    const response = await axios.get(
      `https://projectb2.herokuapp.com/activateUser/`+id,
      { headers }
    );
    console.log(response.data);
    if(response.data.msg === "User account has activated"){
      alert("account activated");
      getUsers();
    }
  };


  return (
    <ThemeProvider theme={TableTheme}>
      <Navbar />
      <div className={classes.adminTable}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#3F4D67", color: "white" }}>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="center">Full Name</TableCell>
                {/* <TableCell align="center">User Name</TableCell> */}
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Status</TableCell>
                {/* <TableCell align="center">Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                Object.keys(users).map((user, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {index}
                    </TableCell>
                    <TableCell align="center">
                      {" "}
                      {users[user].fullname}
                    </TableCell>
                    {/* <TableCell align="center">
                      {" "}
                      {users[user].userName}
                    </TableCell> */}
                    <TableCell align="center">{users[user].email}</TableCell>
                    {/* <TableCell align="center">{users[user].status}</TableCell> */}
                    <TableCell align="center">
                      {users[user].accountStatus === "InActive" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleActivate(users[user]._id)}
                        >
                          Activate
                        </Button>
                      ) : (
                          <p>Activated</p>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};
