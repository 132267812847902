import React from "react";
import { Grid } from "@material-ui/core";
export default function University() {
  return (
    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        height: "60vh",
      }}
    >
      <Grid item md={12} sm={12} xs={12}>
        <h1>Restricted Area</h1>
        <h3>open to select collaborators inside Chamber.Media.</h3>
        <h5>
          If you want advanced access, please send an email to:
          louis@chamber.media OR artin@chamber.media
        </h5>
      </Grid>
    </Grid>
  );
}
