import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/logoBlack.png";
import Bg from "../../Assets/Images/tempBackground.jpg";
import { Navbar } from "../../Components/Navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundImage: `url(${Bg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    letterSpacing: "10px",
  },
  whiteText: {
    color: "#3F4D67",
    textShadow: "1px 2px #8d8998",
    latterSpacing: "1.5",
    [theme.breakpoints.down("xs")]: {
      color: "white",
      textShadow: "none",
    },
  },
}));
const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Navbar />
      <h1 style={{ color: "#3F4D67", textShadow: "1px 2px #8d8998" }}>THE BRAIN</h1>
      <div>
        <img
          alt="Brain Logo, redirecting to brain page"
          src={Logo}
          width="500px"
        />
      </div>
      <h1 className={classes.whiteText}>
        <span> AI </span> Powered, <br />
        <span>Data</span> Driven.
      </h1>
    </div>
  );
};
export default Home;
