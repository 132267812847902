import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player'

const Body = ({ video_links_dropbox, primary_text, addToFav, id }) => {
  let mainText = primary_text;
  let mainTextArray = mainText.split("\n");

  const videoRef = useRef(null);

  let shortContent = "";
  let extraContent = "";
  let allData = "";
  const [readMore, setReadMore] = useState(false);
  const linkName = readMore ? " Read Less " : "Read More ";
  const [video, setVudeo] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    // console.log("force =========== >")
    setVudeo(false);
    setTimeout(() => {
      setVudeo(video_links_dropbox);
    }, 0)
  }, [video_links_dropbox])

  mainTextArray.map((text) => {
    return allData += text;
  });
  allData += mainText;

  shortContent = allData.slice(0, 70);
  extraContent = allData.slice(70);

  const _content = () => {
    return (
      <div style={{ overflow: 'auto', maxHeight: 200 }}>
        {mainTextArray.map((v, i) => (
          <p style={{ margin: 0, padding: 0 }} key={i}>
            {v}
          </p>
        ))}
        <button
          style={{ color: 'black', fontWeight: 'bold' }}
          onClick={() => { setReadMore(!readMore) }}>{linkName}</button>
      </div>
    );
  };
  // console.log(video_links_dropbox)

  const handleError = (e) => {
    console.log(e, id )
    if (e.target) {
      setTimeout(() => {
        // setVudeo("")
        
        // console.log(videoRef.current)
        // videoRef.current.src = video_links_dropbox;
        // videoRef.preload()
      }, 0);
      console.log("error")
      // setVudeo(video_links_dropbox);
      setError(false);
    }
  }

  return (
    <React.Fragment>
      {!error ?
        <div className="post-body">
          <div className="app-body">
            <div className="body-description">
              <div>
                {!readMore && shortContent}
                {readMore && _content()}
                {linkName === "Read More " && <button
                  style={{ color: 'black', fontWeight: 'bold' }}
                  onClick={() => { setReadMore(!readMore) }}>{"..." + linkName}</button>}
              </div>
            </div>
            {extraContent.length < 1 ? (
              <></>
            ) : (
                <div className={"readmoreDiv"}>
                  {/* <button
                className="readMoreButton"
                onClick={() => { setReadMore(!readMore) }}
                href
                >
                {linkName}
              </button> */}
                </div>
              )}
          </div>
          <div className="card-video-wrapper " ref={videoRef}>
            {/* <video
            id="swarn"
            src={video}
            width="800"
            height="800"
            className="swarm-fluid"
            controls
          ></video> */}

            {/* <video
              controls width="800" src={video + "&raw=1"} type="video/mp4" onErrorCapture={e => handleError(e)}
              preload="metadata" controls className="video-box">
              <source src={video + "&raw=1"} type="video/mp4" />
            </video> */}
            <ReactPlayer controls={true} url={video + "&raw=1"} className="video-box"/>
          </div>
        </div>
        :
        <div />
      }
    </React.Fragment>
  );
};

export default Body;
