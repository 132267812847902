import React, { useState } from "react";
import { Button, CircularProgress, Grid, makeStyles, TextField } from "@material-ui/core";
import Logo from "../../Assets/Images/logoBlack.png";
import { Navbar } from "../../Components";
import { useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { api } from "../../Config/api";
import axios from "axios";
import { useStateValue } from "../../Providers/UserProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    letterSpacing: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
    textAlign: "center",
    marginTop: "5rem",
    [theme.breakpoints.down("md")]: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[7],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "28%",
    textAlign: "center",
    padding: "20px",
    border: "none",
    outline: "none",
    borderRadius: "20px",
  },
}));

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [label, setLabel] = useState("");
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [{}, dispatch] = useStateValue();
  const [isLoading, setLoading] = useState(false);

  const handleForgetPassword = () => {
    axios
    .post(api + "/forgotPassword", {
      email: email,
    })
    .then((success) => {
      console.log(success);
      if(success.data?.msg === "You will received email shortly"){
        setModalOpen(false);
      }
    }).catch((e)=>{
      if(e.response.data?.msg == "Invalid email address"){
        alert("invalid email")
      }
    })
  }

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email) {
      setLabel("email is required!");
      return;
    }
    axios
      .post(api + "/login", {
        email: email,
        password: password,
      })
      .then((success) => {
        setLoading(false);
        console.log(success.data?.user?.type);
        if (success.data?.user?.type === "Admin") {
          localStorage.setItem("token", success.data?.token);
          dispatch({
            type: "SET_USER",
            user: { token: success.data?.token, data: success.data?.user },
          });
          history("/admin");
        } else {
          localStorage.setItem("token", success.data?.token);
          localStorage.setItem("userId", success.data?.user?._id);
          dispatch({
            type: "SET_USER",
            user: { token: success.data?.token, data: success.data?.user },
          });
          history("/layout");
        }
        return;
      })
      .catch((err) => {
        console.log(err.response);
        if (
          err.response?.data?.token &&
          err.response?.data?.user?.accountStatus === "active"
        ) {
          setLabel("Signed in");
          dispatch({
            type: "SET_USER",
            user: {
              token: err.response.data?.token,
              data: err.response.data?.user,
            },
          });
          localStorage.setItem("userId", err.response.data?.user?._id);
          localStorage.setItem("token", err.response.data?.token);
          history("/layout");
        } else if (
          err.response?.data?.errors[0]?.msg === "Email does not exists"
        ) {
          setLabel("Email does not exists");
          setLoading(false);
        } else if (err.response?.data?.errors[0]?.msg === "Invalid Password") {
          setLabel("Invalid Password");
          setLoading(false);
        } else {
          setLoading(false);
          setLabel("Your account is not activated yet!");
        }
      });
  };
  const forgetPassword = () => {
    // console.log("Forget Password Button hitt!");
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  const handleResetEmail = () => {
    axios
      .post(api + "/login", {
        email: email,
        password: password,
      })
      .then((success) => {})
      .catch((err) => {});
  };

  return (
    <div className={classes.root}>
      <Navbar />
      <div className={classes.form}>
        <div>
          <img src={Logo} alt="LOGO" width="100px" />
        </div>
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <h1 style={{ color: "#3F4D67" }}>LOGIN</h1>
          </Grid>

          <Grid item md={12} sm={12} xs={12}>
            <TextField
              type="email"
              id="standard-basic1"
              label="Email"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              type="password"
              id="standard-basic2"
              label="Password"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Grid>

          <br />
          <p style={{ textAlign: "center", color: "red" }}>{label}</p>
          <br />
          <Grid item md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#3F4D67", color: "white" }}
              fullWidth
              onClick={login}
            >
              Login
              {isLoading && <CircularProgress color="#fff" size={20} style={{marginLeft:'5px'}} />}
            </Button>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              textAlign: "right",
              letterSpacing: "1px",
              color: "#3F4D67",
              fontWeight: "bold",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            <p onClick={forgetPassword}>Forget Password</p>
          </Grid>
        </Grid>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <img
              className="entryPortalLogo"
              src={Logo}
              href="/"
              alt="Brain Logo"
              width="200px"
            />
            <Grid item md={12} sm={12} xs={12}>
              <h3 style={{ color: "#3F4D67" }}> 
              Please enter your email below.
                <br />A password reset email will be sent to you shortly.
                
              </h3>
            </Grid>
            <TextField
              type="email"
              id="standard-basic"
              label="Email"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <br />
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleForgetPassword}
                style={{ backgroundColor: "#3F4D67", color: "white" }}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                style={{ backgroundColor: "#3F4D67", color: "white" }}
              >
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
