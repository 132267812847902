import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import AssessmentIcon from "@material-ui/icons/Assessment";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import { Link } from "react-router-dom";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FavoriteIcon from '@material-ui/icons/Favorite';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
const drawerWidth = 240;

const IconTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: "white",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

export default function Sidebar({ noText }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={IconTheme}>
        <List>
          <ListItem button>
            <Link to={"/layout"} className="sidebar-gap">
              <ListItemIcon>
                <SearchIcon style={noText && { marginLeft: 3 }} />
              </ListItemIcon>
            </Link>

            <Link to={"/layout"} style={{ color: "#fff" }}>
              {!noText && <ListItemText> Search </ListItemText>}
            </Link>
          </ListItem>
          <ListItem button>
            <Link to={"/analytics"} className="sidebar-gap">
              <ListItemIcon>
                <AssessmentIcon style={noText && { marginLeft: 3 }} />
              </ListItemIcon>
            </Link>

            <Link to={"/analytics"} style={{ color: "#fff" }}>
              {!noText && <ListItemText> Analytics </ListItemText>}
            </Link>
          </ListItem>
          <ListItem button>
            <Link to={"/favourite"} className="sidebar-gap">
              <ListItemIcon>
                <FavoriteIcon style={noText && { marginLeft: 3 }} />
              </ListItemIcon>
            </Link>

            <Link to={"/favourite"} style={{ color: "#fff" }}>
              {!noText && <ListItemText> Favorites </ListItemText>}
            </Link>
          </ListItem>
          <ListItem button>
            <Link to={"/University"} className="sidebar-gap">
              <ListItemIcon>
                <LocalLibrary style={noText && { marginLeft: 3 }} />
              </ListItemIcon>
            </Link>

            <Link to={"/University"} style={{ color: "#fff" }}>
              {!noText && <ListItemText> University </ListItemText>}
            </Link>
          </ListItem>
          <ListItem button >
            <Link to={"/AdComparison"} className="sidebar-gap" >
              <ListItemIcon>
                <CompareArrowsIcon style={noText && { marginLeft: 3 }} />
              </ListItemIcon>
            </Link>

            <Link to={"/AdComparison"} style={{ color: "#fff" }}>
              {!noText && <ListItemText> Ad Comparison</ListItemText>}
            </Link>
          </ListItem>
          <ListItem button >
            <Link to={"/revenue"}  className="sidebar-gap" >
              <ListItemIcon>
                <DonutSmallIcon style={noText && {marginLeft: 3}} />
              </ListItemIcon>
            </Link>

            <Link to={"/revenue"} style={{ color: "#fff" }}>
              {!noText && <ListItemText>Revenue Spy</ListItemText>}
            </Link>
          </ListItem>
        </List>
      </ThemeProvider>
    </div>
  );
}
