import React, { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import {
  makeStyles,
  createStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Sidebar from "./Sidebar";
import Logo from "../Assets/Images/logo.png";
import { ThemeProvider } from "react-bootstrap";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../Providers/UserProvider";

let drawerWidth = 180;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: 70,
      },
    },
    root2: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),

      },
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      backgroundColor: "#FFFFFF",
    },
    appBarSpacer: theme.mixins.toolbar,
    appBar: {
      backgroundColor: "#3F4D67",
      color: "#FFFF",
      border: "none",
      boxShadow: "none !important",
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    appBarSm: {
      backgroundColor: "#3F4D67",
      color: "#FFFF",
      border: "none",
      boxShadow: "none !important",
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${70}px)`,
        marginLeft: 70,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: "#fff",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#3F4D67",
      color: "#FFFF",
      border: "none",
      overflow: "hidden"
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      marginLeft: "auto",
      color: "#fff",
      textAlign: "right",
    },
    fullPaer: {
      width: 70,
      backgroundColor: "#3F4D67",
      color: "#FFFF",
      border: "none",
    }
  })
);

const IconTheme = createMuiTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fill: "black",
      },
    },
  },
});

export const Layout = (props) => {
  const { window } = props;

  const history = useNavigate();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [smDrawe, setSmDrawer] = useState(false);
  const algolia_id = process.env.REACT_APP_ALGOLIA_APP_ID;
  const algolia_key = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
  const searchClient = algoliasearch(algolia_id, algolia_key);
  const [{ user }, dispatch] = useStateValue();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <div className={classes.toolbar}>
      <IconButton
        onClick={() => { setSmDrawer(!smDrawe) }}
        style={{ padding: "20px", color: "white", overflow: 'hidden', }}>
        <img src={Logo} alt="Logo" width="50px" height="50px" />
      </IconButton>
      The Brain
      <Sidebar />
    </div>
  );

  const smDrawer = (
    <div className={classes.toolbar}>
      <IconButton onClick={() => { setSmDrawer(!smDrawe); }}
        style={{ padding: "10px", color: "white" }}>
        <img src={Logo} alt="Logo" width="40px" height="40px" style={{ marginLeft: 3 }} />
      </IconButton>
      <br />
      <Sidebar noText={true} />
    </div>
  );

  const handleLogout = () => {
    console.log("Logout successfull");
    dispatch({
      type: "SET_USER",
      user: {},
    });
    history("/");
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={smDrawe ? classes.root : classes.root2}>
      <ThemeProvider theme={IconTheme}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={smDrawe ? classes.appBarSm : classes.appBar}
          style={{ boxShadow: "none", color: "#042b60" }}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={clsx(
                classes.menuButton,
                mobileOpen && classes.menuButtonHidden
              )}
              style={{ color: "#3F4D67" }}
            >
              <MenuOutlinedIcon />
            </IconButton>
            <div className={classes.title} onClick={handleLogout}>
              {user && user.token && <ListItem button>
                <ListItemText ><ExitToAppIcon style={{ marginBottom: -6 }} /> Logout </ListItemText>
              </ListItem>}
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              // container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: smDrawe ? classes.fullPaer : classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {smDrawe ? smDrawer : drawer}
            </Drawer>
          </Hidden>
          {/* <Hidden xsDown smUp implementation="css">
            <Drawer
            container={container}
            open={mobileOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="temporary"
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden> */}
        </nav>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>
            <div  >
              {props.children}
            </div>
          </div>
        </main>
      </ThemeProvider>
    </div>
  );
};
