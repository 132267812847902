import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import reducer, { initialState } from "./Providers/reducer";
import UserProvider from "./Providers/UserProvider";


if (process.env.NODE_ENV !== "development")
    console.log = () => {};

// Store and setup thunk
// By applying thunk we can return functions in our action creators
const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <UserProvider initialState={initialState} reducer={reducer}>
      <App />
    </UserProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
