import React from "react";
import { Grid } from "@material-ui/core";

export const RevenueGenerator = () => {
  return (
    <div>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          height: "auto",
          fontFamily: "-apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif;",
          fontSize: '14px',
          fontWeight: 'bold'
        }}
      >
        {/* <Grid item md={12} sm={12} xs={12}> */}
        <iframe
          scrolling="no"
          frameBorder="0"
          width="100%"
          height="700px"
          src="https://app.miniextensions.com/form/NFEhwYUZ7p1Hz0kuGEOP?iframeMessageId=VK9m7Dg27g"
        ></iframe>
        {/* </Grid> */}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{
            padding: "0px 120px 0px 120px",
          }}
        >
          <h2>This is an Ad Revenue Estimation tool.</h2>
          <p>
            It works for any Facebook OR Instagram ad - the closest results
            inside this tool will be for Top-of-funnel ads with lots of views
            (because that's where our formulas are based on)
          </p>
          <h2>To Extract Video Views</h2>
          <p style={{display: 'flex', alignItems: 'center', justifyContent : 'center'}}>
           <ol style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'cneter',}}>
           <li>
             Facebook (Desktop) - you'll need to go to incognito mode and click
            the video, 
            </li>
            it should be below the video fold
            <li>
             Facebook (Mobile) -
            it's right below the ad, but sometimes it doesn't show up Instagram
            - always visible under the video ad
            </li>
           </ol>
          </p>
        </Grid>
      </Grid>
    </div>
  );
};
