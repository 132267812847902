/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  CircularProgress,
  Button,
  Typography,
} from "@material-ui/core";
import { Post } from "./Post";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { Layout } from "../../Layouts";
import { useStateValue } from "../../Providers/UserProvider";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import Card from "./Card";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AiFillDelete, AiFillEdit, AiFillFolderAdd, } from "react-icons/ai";
import { BiArrowBack } from 'react-icons/bi'
import DeleteModal from "./DeleteModal";
import DeletePost from "./DeletePost";
import EditModal from "./EditModal";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  searchbar: {
    width: "80%",
    padding: "16px 40px 16px 40px",
    border: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
    borderRadius: "50px",
    outline: "none",
    fontSize: "16px",
  },
  form: {
    width: "100%",
  },
});
const Favourite = () => {
  const [{ user }] = useStateValue();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [folders, setFolder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFolder, setShowFolder] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [selected, setSelected] = useState("");
  const [deleteModal, setDeleteModa] = useState(false);
  const [deletePostModal, setDeletePostModal] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [selectedPost, setSelecetedPost] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [hover, setHover] = useState('');
  const [state, setState] = useState({
    cardData: [],
    current: [],
    filteredList: [],
    hasMore: false,
    filter: false,
    skip: 0,
    limit: 15,
  });
  const history = useNavigate();

  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });

  const getMoreData = () => {
    setState({ ...state, hasMore: true });
    if (state.current.length === state.cardData.length) {
      setState({ ...state, hasMore: false });
      return;
    }
    setTimeout(() => {
      let a = state.current.concat(
        state.cardData.slice(count.prev + 10, count.next + 10)
      );
      setState({ ...state, current: a, hasMore: false });
    }, 2000);
    setCount((prevState) => ({
      ...prevState,
      prev: prevState.prev + 10,
      next: prevState.next + 10,
    }));
  };

  useEffect(() => {
    getCards(state.skip, state.limit);
    getFolder();
  }, []);

  const getCards = async (skip, limit) => {
    console.log("get cards");
    if (!user?.token) history("/");
    const headers = { token: user?.token };
    const response = await axios.post(
      `https://projectb2.herokuapp.com/getAllFavourites?skip=${skip}&limit=${limit}`,
      { user: user?.data?._id },
      { headers }
    );
    if (response.data?.msg === "Favourites Found Successfully") {
      setState({
        ...state,
        cardData: response.data.result,
        skip: state.skip + 15,
      });
      setLoading(false);
    } else {
      alert("no favourites found");
      setLoading(false);
    }
  };

  const getFolder = async () => {
    console.log("folder =========>")
    if (!user?.token) history("/");
    const headers = { token: user?.token };
    const response = await axios.post(
      `https://projectb2.herokuapp.com/getAllFavouriteFolders`,
      { user: user?.data?._id },
      { headers }
    );
    if (response.data?.msg === "Favourite folder found successfully") {
      console.log(response.data?.result?.favouritesFolder)
      setFolder(response.data?.result?.favouritesFolder);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const removeFav = async (id) => {
    // console.log(id)
    if (!user?.token) history("/");
    const headers = { token: user?.token };
    const response = await axios.post(
      `https://projectb2.herokuapp.com/removeFavourite`,
      { user: user?.data?._id, unFavourite: { _id: id } },
      { headers }
    );
    if (response.data?.msg === "Favourite removed Successfully") {
      getCards();
    }
  };

  const showMore = () => {
    getCards(state.skip, state.limit);
  };

  const handleDragEnd = async (result) => {
    console.log(result);
    if (!user?.token) history("/");
    const headers = { token: user?.token };
    const userId = user?.data?._id;

    if (result?.draggableId === result?.source?.index + "f") {
      if (folders.length && result?.destination) {
        let source = folders[result?.source.index];
        let destination = folders[result?.destination?.index];
        const items = Array.from(folders);
        const [reorderedItem] = items.splice(result?.source?.index, 1);
        items.splice(result?.destination?.index, 0, reorderedItem);
        setFolder(items);
        let idsOfFolder = items.map((e)=>e._id);
        console.log('fleder', idsOfFolder);
        const response = await axios.post(
          `https://projectb2.herokuapp.com/sortFavouriteFolders`,
          {
            user: userId,
            favouriteFolders: idsOfFolder,
          },
          { headers }
        );
        console.log(response)
        if(response.data?.msg === "Favourite folders sorted successfully"){
          console.log('sorted')
        }
      }
      return;
    } else {

      setHover('');
      const indexOfdestination = result?.destination?.droppableId;
      const indexOfDragable = result?.draggableId;
      const idOfDestination = folders[indexOfdestination]?._id;
     
     
     
      const idOfFav = state.cardData[indexOfDragable]?._id;
      if (idOfDestination && idOfFav) {
        const response = await axios.post(
          `https://projectb2.herokuapp.com/addFavouriteInFolder`,
          {
            user: userId,
            favourites: { _id: idOfFav },
            favouriteFolderId: idOfDestination,
          },
          { headers }
        );
        // console.log(response);  
        if (response.data?.msg === "Favourites added successfully") {
          let arr = [...state.cardData];
          arr.splice(parseInt(indexOfDragable), 1);
          setState({ ...state, cardData: arr });
          console.log(parseInt(indexOfdestination));
          removeFav(idOfFav);
          getFolder()
        }
      }
    };
  }

  const handleDeletePost = () => {

    let data = state.cardData;
    let filtered = data.filter((e) => e.isSelected);
    let temp = filtered.map((e) => ({ id: e._id, name: e.page_name }));
    console.log(temp);
    if (temp.length) {
      setDeletePostModal(true);
      setSelecetedPost(temp);
    }
  };

  const handleOpenFolder = async (e) => {
    setSelected(e?._id);
    setFolderName(e?.name);
    if (!user?.token) history("/");
    if (e._id !== selected) return;
    const headers = { token: user?.token };
    setShowFolder(true);
    setState({ ...state, cardData: [] });
    const response = await axios.post(
      `https://projectb2.herokuapp.com/getAllFavouritesInFolder`,
      { favouriteFolderId: e?._id },
      { headers }
    );
    if (response.data?.msg === "Favourites Found Successfully") {
      let folderData = response.data?.result;
      if (folderData.length) {
        console.log(folderData);
        history.push({ search: "#" });
        setState({ ...state, cardData: folderData });
      } else {
        setNotFound(true);
      }
    }
  };

  const handleSlectioPost = (index) => {
    let arr = state.cardData;
    if (arr[index].isSelected) {
      arr[index].isSelected = false;
    } else {
      arr[index].isSelected = true;
    }
    // console.log(arr[index].isSelected)
    setState({ ...state, cardData: arr });
  };

  const handleEdit = () => {
    setEditShow(true);
  };

  const handleDelete = (e, d) => {
    console.log(d)
    e.stopPropagation();
    setDeleteModa(true);
    setSelected(d?._id);
    setFolderName(d?.name);
  };

  const handleBack = () => {
    setShowFolder(false);
    setState({ ...state, cardData: [] });
    getCards();
    setNotFound(false);
  };

  if (!state.cardData.length && loading) {
    return (
      <Layout>
        <CircularProgress />
      </Layout>
    );
  }

  return (
    <Layout>
      {!showFolder ? (
        <div className="flex-end">
          {/* <AiFillDelete
            onClick={handleDelete}
            color={selected && "red"}
            style={{ fontSize: 24, marginRight: 30 }}
          /> */}
          <DeleteModal
            selected={selected}
            setModalOpen={setDeleteModa}
            modalOpen={deleteModal}
            folderName={folderName}
            getFolder={getFolder}
          />
          {/* <AiFillEdit
            onClick={handleEdit}
            color={selected && "green"}
            style={{ fontSize: 24, marginRight: 30 }}
          /> */}
          <EditModal
            selected={selected}
            setModalOpen={setEditShow}
            modalOpen={editShow}
            folderName={folderName}
            getFolder={getFolder}
          />
          {/* <Button
          variant="contained"
          color="primary"
          onClick={() => setModalOpen(true)}
          className={classes.button}
          startIcon={<AddIcon />}
        >
          New Folder
      </Button> */}
          <AiFillFolderAdd
            onClick={() => setModalOpen(true)}
            style={{ fontSize: 24 }}
          />
        </div>
      ) : (
          <div className="row line-button">

            {/* <Typography onClick={handleBack} className="button-hover">
            Favourite
          </Typography>
          <Typography style={{ paddingLeft: 8, paddingRight: 8 }}>
            {">"}
          </Typography>
          <Typography>{folderName}</Typography> */}
          </div>
        )}
      <Modal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        getFolder={getFolder}
      />
      <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={e => setHover(e?.destination?.droppableId)}>
        <Droppable droppableId="folder" isDropDisabled={true}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {!showFolder && (
                <Grid container display="flex" className="card-contianer">
                  {!!folders.length &&
                    folders.map((e, i) => {
                      return (

                        <React.Fragment >
                          <Droppable
                            droppableId={i.toString()} key={i}>
                            {(provided) => (
                              <Grid
                                key={i}
                                onClick={() => handleOpenFolder(e)}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={`card-grid`}
                              >
                                <Draggable
                                  draggableId={i.toString() + 'f'}
                                  index={i}
                                  key={e._id.toString()}
                                  className="red"
                                >
                                  {(provided) => (
                                    <span ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >

                                      <Card
                                        index={i} data={e} selected={selected}
                                        handleDelete={handleDelete}
                                        hover={hover}
                                        handleEdit={handleEdit}
                                        className={`${selected === e._id && "border"}`}
                                      />
                                    </span>
                                  )}
                                </Draggable>
                                {provided.placeholder}
                              </Grid>
                            )}
                          </Droppable>
                        </React.Fragment>
                      );
                    })}
                </Grid>
              )}
              {!loading && (
                <div className={classes.root}>
                  {showFolder && (
                    <div className="row-around">
                      <BiArrowBack style={{ fontSize: 20 }} onClick={handleBack} />
                      <Typography>{folderName}</Typography>
                      <AiFillDelete
                        onClick={handleDeletePost}
                        color={selected && "grey"}
                        className="deletedIcon"
                      />
                    </div>
                  )}
                  {!notFound && showFolder && !state.cardData?.length && (
                    <CircularProgress />
                  )}
                  {notFound && showFolder && <p>Empty folder</p>}
                  <DeletePost
                    selected={selected}
                    setModalOpen={setDeletePostModal}
                    modalOpen={deletePostModal}
                    folderName={folderName}
                    selectedPost={selectedPost}
                    handleOpenFolder={handleOpenFolder}
                  />
                  {!!state.cardData.length && (
                    <InfiniteScroll
                      dataLength={state.current.length}
                      next={getMoreData}
                      className="card-sm"
                      style={{ width: '95vw', marginLeft: '5vw' }}
                      hasMore={state.hasMore}
                    >
                      <Grid
                        className={"card-container"}
                        style={{ flexDirection: 'row', justifyContent:'space-around' }}
                        container
                        

                      >
                        {!!state?.cardData &&
                          state?.cardData.length &&
                          state.cardData.map((data, index) => {
                            // console.log(data.isSelected)
                            return (
                              <Draggable
                                draggableId={index.toString()}
                                index={index}
                                key={data._id.toString()}
                                className="red"
                              >
                                {(provided) => (

                                  <Grid
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    onClick={() => handleSlectioPost(index)}
                                    item key={index} lg={4} md={6} sm={12} xs={12} className="card-container2">
                                    <Post
                                      profile_picture={data.profile_pictures}
                                      page_name={data.page_name}
                                      primary_text={data.primary_text}
                                      date_posted={data.date_posted}
                                      fb_video_ad_link={data.fb_video_ad_link}
                                      objectID={data.objectID}
                                      text={data.Category}
                                      video_links_dropbox={
                                        data.video_links_dropbox
                                      }
                                      headline={data.headline}
                                      description={data.description}
                                      commentss={data.commentss}
                                      shares_={data.shares_}
                                      post_reactions={data.post_reactions}
                                      fav={true}
                                      removeFav={removeFav}
                                      id={data._id}
                                      Revenue_Generated={data.Revenue_Generated$}
                                      Price_Generated={data.Averaged_Out_Pricing}
                                      Top_Ad={data.top_percentage_ad}
                                      Secondary_Ad_Type={data.Secondary_Ad_Type_if_it_exists}
                                      Main_Ad_Type={data.Main_Ad_Type}
                                    />
                                  </Grid>

                                )
                                }
                              </Draggable>
                            );
                          })}
                        <Grid
                          container
                          justify={"center"}
                          style={{ padding: 20 }}
                        >
                          {state.cardData.length > 15 && <Button
                            style={{ width: 150, height: 40 }}
                            onClick={() => showMore()}
                          >
                            {state.hasMore ? (
                              <CircularProgress size={18} />
                            ) : (
                                "Show More"
                              )}
                          </Button>}
                        </Grid>
                      </Grid>
                    </InfiniteScroll>
                  )}
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Layout >
  );
};
export default Favourite;
