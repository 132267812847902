import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Container } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../Assets/Images/logo.png";
import { useStateValue } from "../Providers/UserProvider";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navLink: {
    textDecoration: "none",
    color: "#fff",
  },
}));
export const Navbar = () => {
  const classes = useStyles();
  const [{ user }, dispatch] = useStateValue();
  const history = useNavigate();

  const handleLogout = () => {
    console.log("Logout successfull");
    dispatch({
      type: "SET_USER",
      user: {},
    });
    history("/");
  };
  return (
    <div>
      <AppBar position="fixed" style={{ background: "#3F4D67" }}>
        <Container>
          <Toolbar>
            <Link to="/" className={classes.navLink}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <img src={Logo} alt="LOGO" width="50px" height="50px" />
              </IconButton>
            </Link>
            {/* <Link to="/" className={classes.navLink}> */}
            <Typography variant="h6" className={classes.title}>
              The Brain
            </Typography>
            {/* </Link> */}
            <Link to="/layout" className={classes.navLink}>
              <Button color="inherit">Home</Button>
            </Link>
            {user && user.token ? (
              <div className={classes.navLink} onClick={handleLogout}>
                <Button color="inherit">Logout</Button>
              </div>
            ) : (
              <Link to="/login" className={classes.navLink}>
                <Button color="inherit">Login</Button>
              </Link>
            )}
            {user && user.token ? (
              <Link to="/layout" className={classes.navLink}>
                <Button color="inherit">Brain</Button>
              </Link>
            ) : (
              <Link to="/signup" className={classes.navLink}>
                <Button color="inherit">Signup</Button>
              </Link>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
