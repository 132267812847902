import React from 'react';
import * as Icon from 'react-feather';
import { ReactComponent as Wow } from './wow.svg';
import { abbreviateNumber } from "js-abbreviation-number";


const Footer = ({
    commentss,
    shares_,
    post_reactions,
}) => {
    return (
        <div className="post-footer">
            <div className="count-wrapper">
                <div className="likes">
                    <span className="like-ico"><Icon.ThumbsUp /></span>
                    <span className="like-ico"
                        style={{ backgroundColor: '#f55a74', marginLeft: -8 }}
                    >
                        <Icon.Heart style={{ color: 'white', stroke: "white", height: 11, width: 10, marginTop: 1}} />
                    </span>
                    <span className="like-ico"
                        style={{ backgroundColor: 'white', marginLeft: -8 }}
                    >
                        <Wow style={{ color: 'white', stroke: "white", height: '95%', width: '95%',marginTop: 2 }}  src={"./wow.svg"}/>
                    </span>
                    <span>{abbreviateNumber(post_reactions)}  Likes</span>
                </div>
                <div className="comment-heard">
                    <span><span>{commentss ? abbreviateNumber(commentss) : 0} </span>Comments</span>
                    <span><span>{abbreviateNumber(shares_)} </span>Shares</span>
                </div>
            </div>
        </div>
    )
}

export default Footer;