import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { useStateValue } from "../../Providers/UserProvider";
import { CircularProgress } from '@material-ui/core';
import { AiFillDelete, AiFillEdit, } from "react-icons/ai";
import Fab from '@material-ui/core/Fab';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function SimpleCard({ data, selected, handleDelete, handleEdit, hover, index }) {
    const classes = useStyles();

    const [{ user }, dispatch] = useStateValue();
    const [folder, setFolder] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getFolder()
    }, [data]);



    const getFolder = async () => {
        console.log("from getfolder")
        const headers = { token: user?.token }
        const response =
            await axios.post(`https://projectb2.herokuapp.com/getAllFavouritesInFolder?skip=${0}&limit=${4}`,
                { favouriteFolderId: data._id },
                { headers }
            );
        if (response.data?.msg === "Favourites Found Successfully") {
            let arr = response.data?.result;
            if (arr.length > 4) {
                arr.splice(4, arr.length);
            }
            setFolder(arr);
            // console.log(response)
            setLoading(false);
        } else {
            setLoading(false);
            setFolder([]);
        }
    }


    return (
        <Card className={classes.root}
            key={data._id} className={` card-folder
         ${parseInt(hover) === index && "shadow-select"}
           ${selected === data._id && "border"
                }`}>
            <CardContent style={styles.cardContainer}>
                <div style={styles.center}>
                    <Typography className="bold" gutterBottom >
                        {data?.name}
                    </Typography>
                    <div className="row" style={{ width: 100, justifyContent: 'space-around' }}>
                        <Fab color="primary" aria-label="add" size={"small"}>
                            <AiFillDelete
                                onClick={(e) => handleDelete(e, data)}
                                style={{ fontSize: 20 }}
                            />
                        </Fab>
                        <Fab color="primary" aria-label="add" size={"small"}>
                            <AiFillEdit
                                onClick={handleEdit}
                                color={"white"}
                                style={{ fontSize: 20 }}
                            />
                        </Fab>
                    </div>
                </div>
                {loading ? <CircularProgress />
                    :
                    <div className="vidCOntainer">
                        {!!folder?.length && folder.map((e, i) => {
                            // console.log(e.video_links_dropbox+ "&raw=1")
                            return (
                                <video key={i} width="400" preload="metadata" className="image-box">
                                    <source src={e.video_links_dropbox + "&raw=1"} type="video/mp4" className="videocontianer" />
                                </video>
                            )
                        })}
                    </div>
                }
            </CardContent>
        </Card>
    );
}

const styles = {
    cardContainer: {
        width: '100%',
    },
    center: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}