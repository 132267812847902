import React from 'react';

const BuyFooter = ({ headline, description, outbound_URL }) => {
    // console.log(outbound_URL)
    function truncateText(text, length) {
        if (text.length <= length) {
            return text;
        }

        return text.substr(0, length) + "\u2026";
    }



    return (
        <div className="buy-footer" onClick={()=>window.location.href= outbound_URL}>
            <div className="buy-footer-wrapper">
                <div className="footerDescription">
                    <div className="description">
                        <p className={"nomargin"}>{truncateText(headline, 25)}</p>
                    </div>
                    <p className={"nomargin sub"}>{truncateText(description, 25)}</p>
                </div>
                <div>
                    <button className="shopNow">Shop now</button>
                </div>
            </div>

        </div>
    )

}

export default BuyFooter;