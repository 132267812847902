import React from "react";
import Chip from "@material-ui/core/Chip";
import CornerRibbon from "react-corner-ribbon";
import { makeStyles, Tooltip, withStyles } from "@material-ui/core";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles({
  chip_shape: {
    borderRadius: "8px",
  },
});

export default function Adtype({
  Main_Ad_Type,
  Secondary_Ad_Type,
  Revenue_Generated,
  Price_Generated,
  Top_Ad,
}) {
  const classes = useStyles();
  
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  return (
    <div
      style={{
        margin: 8,
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {Top_Ad !== "" && Top_Ad !== undefined && (
        <CornerRibbon
          position="top-left"
          fontColor="white"
          backgroundColor="#4CAF50"
        >
          {"Top 5%"}
        </CornerRibbon>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginLeft: "87px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Chip
            label={Main_Ad_Type}
            className="chip_ad long_text"
            style={{
              
              fontSize: 12,
              marginRight: 3,
              background:
                Main_Ad_Type === "Product Demo"
                  ? "#2196F3"
                  : Main_Ad_Type === "Lifestyle"
                  ? "#FF9800"
                  : Main_Ad_Type === "Social Proof"
                  ? "#009688"
                  : Main_Ad_Type === "Unboxing"
                  ? "#795548"
                  : Main_Ad_Type === "Social Proof"
                  ? "#4CAF50"
                  : Main_Ad_Type === "Spokesperson Anchor"
                  ? "#9C27B0"
                  : Main_Ad_Type === "Dynamic Ad"
                  ? "#E91E63"
                  : "",
              whiteSpace: "initial",
            }}
          />

          {Secondary_Ad_Type !== "" && (
            <Chip
              label={Secondary_Ad_Type}
              className="chip_ad"
              style={{
                whiteSpace: "initial",
                marginRight: 5,
                marginTop: 5,
                marginLeft: 8,
                fontSize: 12,
                background:
                  Secondary_Ad_Type === "Product Demo"
                    ? "#2196F3"
                    : Secondary_Ad_Type === "Lifestyle"
                    ? "#FF9800"
                    : Secondary_Ad_Type === "Social Proof"
                    ? "#009688"
                    : Secondary_Ad_Type === "Unboxing"
                    ? "#795548"
                    : Secondary_Ad_Type === "Social Proof"
                    ? "#4CAF50"
                    : Secondary_Ad_Type === "Spokesperson Anchor"
                    ? "#9C27B0"
                    : Secondary_Ad_Type === "Dynamic Ad"
                    ? "#E91E63"
                    : "",
              }}
            />
          )}
        </div>
      </div>
      <div className="card_prices">
        <LightTooltip
          title={`Revenue Generated is an estimated value we calculate using customized formulas developed inside The Brain using our internal data - we account for Video Views, Conversion Rates, CTR's and AOV's multipliers.
            This value corresponds to this single ad creative and doesn't take into account potential extra earnings that come from retargeting campaigns.`}
          style={{ color: "white", fontSize: "12px" }}
        >
          <Chip
            label={`Rev Generated: ${
              Revenue_Generated === "" || Revenue_Generated === "$0"
                ? "N/A"
                : "$" + numberWithCommas(Revenue_Generated)
            }`}
            className="chip_ad"
            style={{
              marginRight: 5,
              fontSize: 12,
              background: "#4CAF50",
            }}
          />
        </LightTooltip>

        <LightTooltip
          title={
            "Averaged Out Pricing is the final price of the product page - it can either be composed of a single product price OR an average of up to 3 product prices (either because it’s a collection page, or because the product has several pricing tiers)."
          }
        >
          <Chip
            label={`Product Price(s): ${
              Price_Generated === "" || Price_Generated === "$0"
                ? "N/A"
                : "$" + Price_Generated
            }`}
            className="chip_ad"
            style={{
              marginRight: 5,
              marginTop: 5,
              fontSize: 12,
              background: "#4CAF50",
            }}
          />
        </LightTooltip>
      </div>
    </div>
  );
}
