import React, { useState } from "react";
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import Logo from "../../Assets/Images/logoBlack.png";
import { Navbar } from "../../Components";
import { useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from 'axios';
import { api } from '../../Config/api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    letterSpacing: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5rem",
    width: "30%",
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      width: "70%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[7],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "50%",
    textAlign: "center",
    border: "none",
    outline: "none",
    borderRadius: "20px",
    padding: theme.spacing(2, 4, 3),
  },
}));

export const Signup = () => {
  const classes = useStyles();
  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const signup = (e) => {
    e.preventDefault();

    axios.post(api + '/signup', {
      fullname: fullName,
      username: userName,
      email: email,
      password: password
    })
      .then((success) => {
        console.log("database saved!", success?.data.msg);
        if(success?.data.msg){
          setModalOpen(true);
          clearFields();
        }
      })
      .catch((err) => {
        let msg= err?.response?.data?.errors[0]?.msg;
        let erMsg = msg  ?  msg : "";
        console.log(err?.response?.data?.errors[0]?.msg)
      
        alert(
          !msg ?
          "Error While creating a user, Something went wrong with Email or password, Enter the Valid Email and password!"
          :
          erMsg
        );
      });
  };
  const handleClose = () => {
    setModalOpen(!modalOpen);
    history.replace("/");
  };

  const clearFields = () => {
    setFullName("");
    setUserName("");
    setEmail("");
    setPassword("");
  };
  return (
    <div className={classes.root}>
      <Navbar />
      <div className={classes.form}>
        <div>
          <img src={Logo} alt="LOGO" width="100px" />
        </div>
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <h1 style={{ color: "#3F4D67" }}>SIGNUP</h1>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              type="text"
              id="standard-basic"
              label="Full Name"
              fullWidth
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              type="text"
              id="standard-basic"
              label="User Name"
              fullWidth
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              type="email"
              id="standard-basic"
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              type="password"
              id="standard-basic"
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#3F4D67", color: "white" }}
              fullWidth
              onClick={signup}
            >
              Signup
            </Button>
          </Grid>
        </Grid>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            <img
              className="entryPortalLogo"
              src={Logo}
              href="/"
              alt="Brain Logo"
              width="200px"
            />
            <h1 id="transition-modal-title" style={{ color: "#3F4D67" }}>
              Congratulations! 😃
            </h1>
            <p id="transition-modal-description" style={{ color: "#3F4D67" }}>
              Thank you for signing up! 
              <br /> If you signed up using your Chamber Media email. 
              <br /> You will receive a confirmation link in your email.
              <br /> If you used any other email, you will need to wait for approval from the Gatekeeper. 
            </p>
            <h3 style={{ color: "#3F4D67" }}>Thank you for signing up! ❤️ </h3>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              style={{ backgroundColor: "#3F4D67", color: "white" }}
            >
              Close
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
