import filterReducer from "./filterReducer";
import userReducer from "./userReducer";
import { combineReducers } from "redux";

// Root Reducer
const rootReducer = combineReducers({
  filterReducer,
  userReducer,
});

export default rootReducer;
