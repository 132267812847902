import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  CircularProgress,
  AccordionSummary,
  Button,
  AccordionDetails,
  Accordion,
  Typography,
  Slider,
} from "@material-ui/core";
import { Layout } from "../../Layouts";
import ChartBox from "../../Components/ChartBox";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import { useStateValue } from "../../Providers/UserProvider";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterBay from "../Search/filter";
import Paper from "@material-ui/core/Paper";
import InfoCard from "./InfoCard";
import Bar from "./Bar";
import Chart2 from "./Chart2";
import ScatterPlot from "./ScatterPlot";
import { useSelector } from "react-redux";

let searchKey;
const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  searchbar: {
    width: "80%",
    padding: "16px 40px 16px 40px",
    border: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
    borderRadius: "50px",
    outline: "none",
    fontSize: "16px",
  },
  form: {
    width: "100%",
  },
});

function Analytics() {
  const [dataPoints, setDatapoints] = useState();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [{ user }] = useStateValue();
  const [show, setShow] = useState(false);
  const history = useNavigate();
  const [data, setData] = useState("");
  const selectedData = useSelector((state) => state.filterReducer);
  const [expnad, setExpand] = useState(false);
  const [total, setTotal] = useState(0);
  const [filterName, setFilterName] = useState("");
  useEffect(() => {
    let timer;
    let refine;
    const filters = selectedData?.refinements;
    console.log(filters);
    if (
      (filters?.Classification && filters?.Classification?.length) ||
      filters?.adScore?.length ||
      filters?.comments?.length ||
      filters?.likes?.length ||
      filters?.personName?.length ||
      filters?.shares?.length ||
      filters?.verifiedFb ||
      filters?.vidLength?.length ||
      filters?.pricing?.length ||
      filters?.editType?.length ||
      filters?.adType?.length ||
      filters?.dateRange
    ) {
      timer = setTimeout(() => {
        getFilteredCards();
      }, 1000);
    } else {
      console.log("Ge5t cards");
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, [selectedData]);

  const getFilteredCards = async () => {
    console.log("Selected", selectedData);
    setLoading(true);
    const headers = { token: user.token };
    const data = {
      category: selectedData.refinements.personName, // array of string
      classification: selectedData.refinements.Classification, // array of string ,
      adTypes: selectedData?.refinements?.adType,
      ad_score: selectedData?.refinements?.adScore?.length
        ? `${
            selectedData.refinements?.adScore[0] +
            "/" +
            selectedData.refinements?.adScore[1]
          }`
        : "",
      verified_facebook:
        selectedData.refinements.verifiedFb === "No" ? true : false,
      verified_instagram:
        selectedData.refinements.verifiedFb === "No" ? true : false,
      video_duration: !!selectedData.refinements.vidLength.length && {
        range1: selectedData.refinements.vidLength[0],
        range2: selectedData.refinements.vidLength[1],
      },
      likes: selectedData?.refinements?.likes?.length
        ? {
            range1: selectedData.refinements.likes[0],
            range2: selectedData.refinements.likes[1],
          }
        : { range1: 0, range2: 450000 },
      comments: selectedData?.refinements?.comments?.length
        ? {
            range1: selectedData?.refinements?.comments[0],
            range2: selectedData.refinements.comments[1],
          }
        : { range1: 0, range2: 78000 },
      shares: selectedData?.refinements?.shares?.length
        ? {
            range1: selectedData?.refinements?.shares[0],
            range2: selectedData?.refinements?.shares[1],
          }
        : { range1: 0, range2: 101000 },
      Averaged_Out_Pricing: selectedData?.refinements?.pricing?.length
        ? {
            range1: selectedData?.refinements?.pricing[0],
            range2: selectedData.refinements.pricing[1],
          }
        : "",
      editTypes: selectedData?.refinements?.editType,
      keyword: search,
      dateRange: [selectedData?.refinements?.dateRange?.start,selectedData?.refinements?.dateRange?.end ],
    };
    setFilterName(selectedData.refinements.personName);
    console.log("added", data);
    const response = await axios.post(
      `https://projectb2.herokuapp.com/filterAnalytics`,
      // `http://localhost:4000/filterAnalytics`,
      data,
      { headers }
    );
    console.log("resp---", response.data);
    if (response.data && response.status == 200) {
      if (response.data?.totalResults === 0) {
        alert("no results");
      } else {
        const total = response?.data?.totalResults;
        setData(response.data);
        setTotal(total);
        setDatapoints();

        let arr = [
          {
            key: [
              {
                name: "dynamic_ads",
                value: response?.data?.dynamic_ads,
              },
              {
                name: "social_proof",
                value: response?.data?.social_proof,
              },
              {
                name: "lifestyle",
                value: response?.data?.lifestyle,
              },
              {
                name: "Product_Demo",
                value: response?.data?.Product_Demo,
              },
              {
                name: "Spokesperson Anchor",
                value: response?.data?.Spokesperson_Anchor,
              },
              {
                name: "dynamic_ads_views",
                value: response?.data?.dynamic_ads_views,
              },
              {
                name: "social_proof_views",
                value: response?.data?.social_proof_views,
              },
              {
                name: "lifestyle_views",
                value: response?.data?.lifestyle_views,
              },
              {
                name: "Product_Demo_views",
                value: response?.data?.Product_Demo_views,
              },

              {
                name: "Spokesperson Anchor Views",
                value: response?.data?.Spokesperson_Anchor_views,
              },

              {
                name: "Unboxing",
                value: response?.data?.unboxing_views,
              },
              {
                name: "views",
                value: response?.data?.views,
              },
              {
                name: "Case Study Views",
                value: response?.data?.case_study_views,
              },
              {
                name: "Case Study",
                value: response?.data?.case_study,
              },
            ],
          },
          {
            key: [
              {
                name: "Highly_edited_videos",
                value: response.data.Highly_edited_video_views,
              },
              {
                name: "Raw_cuts",
                value: response.data.Raw_cuts_views,
              },
              {
                name: "views",
                value: response.data.views,
              },
            ],
          },
          {
            key: [
              {
                name: "Primary text word count",
                value: response.data.primary_text_word_count,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.primary_text_word_count /
                    response.data.totalResults
                ),
              },
              {
                name: "Min",
                value: response.data.minimum_primary_text_word_count,
              },
              {
                name: "Max",
                value: response.data.maximum_primary_text_word_count,
              },
            ],
          },
          {
            key: [
              {
                name: "Text Score Flesch Kincaid",
                value: response.data.text_score_flesch_kincaid,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.text_score_flesch_kincaid /
                    response.data.totalResults
                ),
              },
              {
                name: "Min",
                value: response.data.minimum_text_score_flesch_kincaid,
              },
              {
                name: "Max",
                value: response.data.maximum_text_score_flesch_kincaid,
              },
            ],
          },
          {
            key: [
              {
                name: `Offer Discount`,
                value: Math.round(
                  (response.data.viewsInDiscounts / response.data.views) * 100 -
                    100
                ),
                actualValue: response.data.discount,
              },
              {
                name: "Does not offer Discount",
                value: Math.round(
                  (response.data.viewsNotDiscount / response.data.views) * 100 -
                    100
                ),
                actualValue: total - response.data.discount,
              },
            ],
          },
          {
            key: [
              {
                name: `Includes Testimonial`,
                value: Math.round(
                  (response.data.viewInTestimonial / response.data.views) *
                    100 -
                    100
                ),
                actualValue: response.data.testimonial_in_copy,
              },
              {
                name: "Does not include Testimonial",
                value: Math.round(
                  (response.data.viewNotTestimonial / response.data.views) *
                    100 -
                    100
                ),
                actualValue: total - response.data.testimonial_in_copy,
              },
            ],
          },
          {
            key: [
              {
                name: "Mentions Shipping In Copy",
                value: Math.round(
                  (response.data.viewsInShippingInCopy / response.data.views) *
                    100 -
                    100
                ),
                actualValue: response.data.mentions_shipping_in_copy,
              },
              {
                name: "Does Not Include Shipping",
                value: Math.round(
                  (response.data.viewsNotShippingInCopy / response.data.views) *
                    100 -
                    100
                ),
                actualValue: total - response.data.mentions_shipping_in_copy,
              },
            ],
          },
          {
            key: [
              {
                name: "Includes Free Shipping",
                value: Math.round(
                  (response.data.viewInShipping / response.data.views) * 100 -
                    100
                ),
                actualValue: response.data.free_shipping,
              },
              {
                name: "Does not include Free Shipping",
                value: Math.round(
                  (response.data.viewNotShipping / response.data.views) * 100 -
                    100
                ),
                actualValue: total - response.data.free_shipping,
              },
            ],
          },
          {
            key: [
              {
                name: "Uses “!” on the Headline?",
                value: response.data.uses_on_the_Headline,
              },
              {
                name: "Does not Uses “!”",
                value: total - response.data.uses_on_the_Headline,
              },
            ],
          },
          {
            key: [
              {
                name: "Outbound URL is Homepage?",
                value: Math.round(
                  (response.data.viewsInOutbound / response.data.views) * 100 -
                    100
                ),
                actualValue: response.data.outbound_URL_is_Homepage,
              },
              {
                name: "Outbound URL is not Homepage?",
                value: Math.round(
                  (response.data.viewsNotOutbound / response.data.views) * 100 -
                    100
                ),
                actualValue: total - response.data.outbound_URL_is_Homepage,
              },
            ],
          },
          {
            key: [
              {
                name: "CTA URL on the Copy?",
                value: response.data.CTA_URL_on_the_Copy,
              },
              {
                name: "No CTA URL on the Copy",
                value: total - response.data.outbound_URL_is_Homepage,
              },
            ],
          },
          {
            key: [
              {
                name: "Description Used",
                value: Math.round(
                  (response.data.viewsInDescription / response.data.views) *
                    100 -
                    100
                ),
                actualValue: response.data.description_used,
              },
              {
                name: "Does Not Include Description",
                value: Math.round(
                  (response.data.viewsNoDescription / response.data.views) *
                    100 -
                    100
                ),
                actualValue: total - response.data.description_used,
              },
            ],
          },
          {
            key: [
              { name: "Uses ® or ™ in the copy?", value: 400 },
              { name: "Does not  Uses ® or ™ in the copy", value: 300 },
            ],
          },
          {
            key: [
              {
                name: "Text Score Dale Chall",
                value: response.data.text_score_dale_chall,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.text_score_dale_chall /
                    response.data.totalResults
                ),
              },
              {
                name: "Min",
                value: response.data.minimum_text_score_dale_chall,
              },
              {
                name: "Max",
                value: response.data.maximum_text_score_dale_chall,
              },
            ],
          },
          {
            key: [
              {
                name: "Headline word count",
                value: response.data.headline_word_count,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.headline_word_count / response.data.totalResults
                ),
              },
              { name: "Min", value: response.data.minimum_headline_word_count },
              { name: "Max", value: response.data.maximum_headline_word_count },
            ],
          },
          {
            key: [
              {
                name: "Ideal Video Creative Length",
                value: response.data?.Average_Video_Creative_Length,
              },
            ],
          },
          {
            key: [
              {
                name: "Product Price",
                value: response.data?.Product_Price,
              },
            ],
          },
          {
            key: [
              {
                name: `Word you in Primary Text  `,
                value: response.data.uses_word_you_in_primary_text,
              },
              {
                name: "Does not use word You in Primary Text ",
                value: total - response.data.uses_word_you_in_primary_text,
              },
            ],
          },
        ];

        setDatapoints(arr);
        setLoading(false);
        setShow(true);
      }
      setLoading(false);
    } else if (!response.data?.length) {
      setLoading(false);
    } else {
      alert("something went wrong");
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    console.log(search);
    // return
    if (!user?.token) {
      alert("login again");
      history.push("/layout");
    }
    if (search) {
      setLoading(true);

      // Get token
      const headers = { token: user?.token };
      // console.log(user?.token)
      const response = await axios.post(
        `https://projectb2.herokuapp.com/filterAnalytics`,
        // `http://localhost:4000/analytics`,
        { keyword: search },
        { headers }
      );
      if (response?.data) {
        const total = response?.data?.totalResults;
        // console.log(response?.response);
        createScatter(response?.data);
        setData(response.data);
        setDatapoints();
        let arr = [
          {
            key: [
              {
                name: "dynamic_ads",
                value: response?.data?.dynamic_ads,
              },
              {
                name: "social_proof",
                value: response?.data?.social_proof,
              },
              {
                name: "lifestyle",
                value: response?.data?.lifestyle,
              },
              {
                name: "Product_Demo",
                value: response?.data?.Product_Demo,
              },
              {
                name: "Spokesperson Anchor",
                value: response?.data?.Spokesperson_Anchor,
              },
              {
                name: "dynamic_ads_views",
                value: response?.data?.dynamic_ads_views,
              },
              {
                name: "social_proof_views",
                value: response?.data?.social_proof_views,
              },
              {
                name: "lifestyle_views",
                value: response?.data?.lifestyle_views,
              },
              {
                name: "Product_Demo_views",
                value: response?.data?.Product_Demo_views,
              },

              {
                name: "Spokesperson Anchor Views",
                value: response?.data?.Spokesperson_Anchor_views,
              },

              {
                name: "Unboxing",
                value: response?.data?.unboxing_views,
              },
              {
                name: "views",
                value: response?.data?.views,
              },
              {
                name: "Case Study Views",
                value: response?.data?.case_study_views,
              },
              {
                name: "Case Study",
                value: response?.data?.case_study,
              },
            ],
          },
          {
            key: [
              {
                name: "Highly_edited_videos",
                value: response.data.Highly_edited_video_views,
              },
              {
                name: "Raw_cuts",
                value: response.data.Raw_cuts_views,
              },
              {
                name: "views",
                value: response.data.views,
              },
            ],
          },
          {
            key: [
              {
                name: "Primary text word count",
                value: response.data.primary_text_word_count,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.primary_text_word_count /
                    response.data.totalResults
                ),
              },
              {
                name: "Min",
                value: response.data.minimum_primary_text_word_count,
              },
              {
                name: "Max",
                value: response.data.maximum_primary_text_word_count,
              },
            ],
          },
          {
            key: [
              {
                name: "Text Score Flesch Kincaid",
                value: response.data.text_score_flesch_kincaid,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.text_score_flesch_kincaid /
                    response.data.totalResults
                ),
              },
              {
                name: "Min",
                value: response.data.minimum_text_score_flesch_kincaid,
              },
              {
                name: "Max",
                value: response.data.maximum_text_score_flesch_kincaid,
              },
            ],
          },
          {
            key: [
              {
                name: `Offer Discount`,
                value: Math.round(
                  (response.data.viewsInDiscounts / response.data.views) * 100 -
                    100
                ),
                actualValue: response.data.discount,
              },
              {
                name: "Does not offer Discount",
                value: Math.round(
                  (response.data.viewsNotDiscount / response.data.views) * 100 -
                    100
                ),
                actualValue: total - response.data.discount,
              },
            ],
          },
          {
            key: [
              {
                name: `Includes Testimonial`,
                value: Math.round(
                  (response.data.viewInTestimonial / response.data.views) *
                    100 -
                    100
                ),
                actualValue: response.data.testimonial_in_copy,
              },
              {
                name: "Does not include Testimonial",
                value: Math.round(
                  (response.data.viewNotTestimonial / response.data.views) *
                    100 -
                    100
                ),
                actualValue: total - response.data.testimonial_in_copy,
              },
            ],
          },
          {
            key: [
              {
                name: "Mentions Shipping In Copy",
                value: Math.round(
                  (response.data.viewsInShippingInCopy / response.data.views) *
                    100 -
                    100
                ),
                actualValue: response.data.mentions_shipping_in_copy,
              },
              {
                name: "Does Not Include Shipping",
                value: Math.round(
                  (response.data.viewsNotShippingInCopy / response.data.views) *
                    100 -
                    100
                ),
                actualValue: total - response.data.mentions_shipping_in_copy,
              },
            ],
          },
          {
            key: [
              {
                name: "Includes Free Shipping",
                value: Math.round(
                  (response.data.viewInShipping / response.data.views) * 100 -
                    100
                ),
                actualValue: response.data.free_shipping,
              },
              {
                name: "Does not include Free Shipping",
                value: Math.round(
                  (response.data.viewNotShipping / response.data.views) * 100 -
                    100
                ),
                actualValue: total - response.data.free_shipping,
              },
            ],
          },
          {
            key: [
              {
                name: "Uses “!” on the Headline?",
                value: response.data.uses_on_the_Headline,
              },
              {
                name: "Does not Uses “!”",
                value: total - response.data.uses_on_the_Headline,
              },
            ],
          },
          {
            key: [
              {
                name: "Outbound URL is Homepage?",
                value: Math.round(
                  (response.data.viewsInOutbound / response.data.views) * 100 -
                    100
                ),
                actualValue: response.data.outbound_URL_is_Homepage,
              },
              {
                name: "Outbound URL is not Homepage?",
                value: Math.round(
                  (response.data.viewsNotOutbound / response.data.views) * 100 -
                    100
                ),
                actualValue: total - response.data.outbound_URL_is_Homepage,
              },
            ],
          },
          {
            key: [
              {
                name: "CTA URL on the Copy?",
                value: response.data.CTA_URL_on_the_Copy,
              },
              {
                name: "No CTA URL on the Copy",
                value: total - response.data.outbound_URL_is_Homepage,
              },
            ],
          },
          {
            key: [
              {
                name: "Description Used",
                value: Math.round(
                  (response.data.viewsInDescription / response.data.views) *
                    100 -
                    100
                ),
                actualValue: response.data.description_used,
              },
              {
                name: "Does Not Include Description",
                value: Math.round(
                  (response.data.viewsNoDescription / response.data.views) *
                    100 -
                    100
                ),
                actualValue: total - response.data.description_used,
              },
            ],
          },
          {
            key: [
              { name: "Uses ® or ™ in the copy?", value: 400 },
              { name: "Does not  Uses ® or ™ in the copy", value: 300 },
            ],
          },
          {
            key: [
              {
                name: "Text Score Dale Chall",
                value: response.data.text_score_dale_chall,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.text_score_dale_chall /
                    response.data.totalResults
                ),
              },
              {
                name: "Min",
                value: response.data.minimum_text_score_dale_chall,
              },
              {
                name: "Max",
                value: response.data.maximum_text_score_dale_chall,
              },
            ],
          },
          {
            key: [
              {
                name: "Headline word count",
                value: response.data.headline_word_count,
              },
              {
                name: "Average word count",
                value: Math.round(
                  response.data.headline_word_count / response.data.totalResults
                ),
              },
              { name: "Min", value: response.data.minimum_headline_word_count },
              { name: "Max", value: response.data.maximum_headline_word_count },
            ],
          },
          {
            key: [
              {
                name: "Ideal Video Creative Length",
                value: response.data?.Average_Video_Creative_Length,
              },
            ],
          },
          {
            key: [
              {
                name: "Product Price",
                value: response.data?.Product_Price,
              },
            ],
          },
          {
            key: [
              {
                name: `Word you in Primary Text  `,
                value: response.data.uses_word_you_in_primary_text,
              },
              {
                name: "Does not use word You in Primary Text ",
                value: total - response.data.uses_word_you_in_primary_text,
              },
            ],
          },
        ];

        setDatapoints(arr);
      }
      setLoading(false);
      setShow(true);
    }
  };

  function getSecondPart(str) {
    return str.split(",")[1];
  }
  const createScatter = (data) => {
    const arr = data.text_score_dale_chall_array;
    if (arr.length) {
      let temp = [];
      arr.forEach((element) => {
        let date = element.date.slice(0, element.date.lastIndexOf(",") + 1);
        let d = getSecondPart(date);
        //   console.log(d);
      });
    }
  };

  const hadnlekeydown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  console.log("data ----", data);
  return (
    <Layout>
      <div className={classes.root}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            className={classes.searchbar}
            onChange={(value) => {
              setSearch(value.target.value);
            }}
            type="text"
            placeholder="Search Here...."
            // value={search}
            onKeyDown={hadnlekeydown}
          />

          <SearchIcon
            onClick={getFilteredCards}
            style={{
              backgroundColor: "#3f4d67",
              marginLeft: 10,
              width: 40,
              height: 40,
              padding: 5,
              borderRadius: 30,
            }}
            color="secondary"
          />
        </div>
        <Accordion
          style={{
            background: "transparent",
            border: "none",
            boxShadow: "none",
            marginTop: "20px",
          }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Button
              onClick={() => setExpand((e) => !e)}
              style={{ margin: "0 auto" }}
            >
              {expnad ? "-" : "+"} Additional Filters
            </Button>
          </AccordionSummary>

          <AccordionDetails>
            <FilterBay />
          </AccordionDetails>
        </Accordion>
        {loading && <CircularProgress size={18} />}
        {show && dataPoints && (
          <>
            <Typography className="lower">Analytics</Typography>
            <br />
            <br />
            <Grid
              container
              spacing={1}
              direction="column"
              alignItems="center"
              justify="center"
              style={{
                padding: 20,
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item md={12} sm={12} xs={12} lg={12}>
                <div style={{}}>
                  <InfoCard
                    search={search}
                    data={data}
                    num={data.totalResults}
                    title={"Ads"}
                    filterName={filterName}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        )}
        <br />
        <br />
        <Grid
          container
          spacing={1}
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {show && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                // marginLeft: "20%",
                alignSelf: "center",
              }}
              className="card-middle"
            >
              {show &&
                dataPoints &&
                !!dataPoints.length &&
                dataPoints.map((item, index) => {
                  // console.log("item,", item?.key[0]?.name)
                  if (index === 2 || index === 3 || index === 14) {
                    return (
                      <Grid
                        className="card-middle-container"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                      >
                        <InfoCard
                          search={search}
                          data={item}
                          num={
                            item?.key[1]?.value
                              ? item?.key[1]?.value
                              : item?.key[0]?.value
                          }
                          title={item?.key[0]?.name}
                          subtitle={item?.key[1]?.name}
                          type="number"
                          index={index}
                        />
                        {/* <Chart2
                      chartdata={item}
                      index={index}
                      allData={data}
                      search={search}
                      total={total}
                    /> */}
                      </Grid>
                    );
                  }
                })}
            </div>
          )}

          {show && (
            <div
              style={{
                display: "flex",
                width: "70%",
                padding: "10px",
                justifyContent: "center",
                marginTop: 20,
              }}
              className="card-middle"
            >
              {show &&
                dataPoints &&
                !!dataPoints.length &&
                dataPoints.map((item, index) => {
                  // console.log("item,", item?.key[0]?.name)
                  if (index === 15 || index === 16) {
                    return (
                      <Grid
                        item
                        item
                        lg={4}
                        md={4}
                        sm={4}
                        xs={4}
                        container
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <InfoCard
                          search={search}
                          data={item}
                          num={
                            item?.key[1]?.value
                              ? item?.key[1]?.value
                              : item?.key[0]?.value
                          }
                          title={item?.key[0]?.name}
                          subtitle={item?.key[1]?.name}
                          type="number"
                          index={index}
                          secondrow={true}
                          totalResults={data.totalResults}
                        />
                        {/* <Chart2
                      chartdata={item}
                      index={index}
                      allData={data}
                      search={search}
                      total={total}
                    /> */}
                      </Grid>
                    );
                  }
                })}
            </div>
          )}
          <br />
          <br />
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {show &&
              dataPoints &&
              !!dataPoints.length &&
              dataPoints.map((item, index) => {
                if (
                  index !== 2 &&
                  index !== 3 &&
                  index !== 14 &&
                  index !== 15 &&
                  index !== 16 &&
                  index !== 13 &&
                  index !== 17 &&
                  index !== 12 &&
                  index !== 8 &&
                  index !== 6 &&
                  index !== 10
                ) {
                  return (
                    <Grid
                      item
                      style={{ marginRight: 20 }}
                      lg={12}
                      md={index === 0 || (index === 1 && 12)}
                      sm={index === 0 || (index === 1 && 12)}
                      xs={index === 0 || (index === 1 && 12)}
                    >
                      <br />
                      <br />
                      <ChartBox
                        chartdata={item}
                        index={index}
                        allData={data}
                        search={search}
                        total={total}
                      />
                    </Grid>
                  );
                }
              })}
          </span>
        </Grid>
      </div>
    </Layout>
  );
}

export default Analytics;
