import React from 'react';
import * as Icon from 'react-feather';
import Fab from '@material-ui/core/Fab';
import { AiOutlineHeart, AiTwotoneHeart } from 'react-icons/ai';

const Header = ({
    profile_picture,
    page_name,
    date_posted,
    fb_video_ad_link,
    addToFav,
    id,
    fav,
    removeFav
}) => {

    const handleStar = () => {
        if (fav && removeFav) {
            removeFav(id);
        } else {
            addToFav(id)
        }
    }

    
    return (
        <div className="post-header">

            <div className="post-author">
                <img src={profile_picture} style={{ width: 40, height: 40 }} alt={""} />
                <div className="author-name">
                    <div className="page_name">
                        <a href="https://www.facebook.com" >{page_name}</a>
                    </div>
                    <timestamp is="custom">
                        <a href="https://www.facebook.com" ><Icon.Clock style={styles.iconClock} /><p style={styles.date}>{date_posted}</p></a>
                    </timestamp>
                </div>
            </div>
            <div className={'icons-div'}>
                <Fab color="primary" aria-label="like" onClick={handleStar} size={"small"}
                   
                    className="post-fav post-option blue " >
                    {!fav ?
                        <AiOutlineHeart size={22} style={{ marginTop: -2}} className="icon-heart" /> 
                        :
                        <AiTwotoneHeart color="red" size={22} className="red-color icon-heart" style={{ marginTop: -2, marginRight: 2, color: 'red' }} />
                    }
                </Fab>
                {/* <Icon.Star style={fav && { color: '#f7d21a' }} size={22} /> */}

                <a href={fb_video_ad_link} target={"blank"} className="post-option">
                    <Icon.Facebook />
                </a>
            </div>

        </div>
    )
}

const styles = {
    iconClock: { width: 16, height: 14, marginLeft: -7 },
    date: {
        padding: 0,
        margin: 0,
        marginLeft: -2
    }
}

export default Header;