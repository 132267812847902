import React, { useState } from "react";
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStateValue } from "../../Providers/UserProvider";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        letterSpacing: "10px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "30%",
        backgroundColor: "#fff",
        padding: "30px",
        borderRadius: "20px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
        textAlign: "center",
        marginTop: "5rem",
        [theme.breakpoints.down("md")]: {
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[7],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "50%",
        textAlign: "center",
        padding: "20px",
        border: "none",
        outline: "none",
        borderRadius: "20px",
    },
}));

export default function CustomModal({ setModalOpen, modalOpen, getFolder }) {
    const [folder, setFolder] = useState("");
    const [{ user }] = useStateValue();

    const classes = useStyles();

    const handleClose = async () => {
        if (folder) {
            const headers = { token: user?.token };
            console.log(user)
            const response = await axios.post(
                `https://projectb2.herokuapp.com/createFavouriteFolder`,
                { user: user?.data?._id, name: folder },
                { headers }
            );
            getFolder()

            if (response?.data?.length) {
                console.log(response.data);
            } else {
                setModalOpen(false);
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={modalOpen}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={modalOpen}>
                <div className={classes.paper}>

                    <Grid item md={12} sm={12} xs={12}>
                        <h3 style={{ color: "#3F4D67" }}>
                            Name of the Folder
              </h3>
                    </Grid>
                    <TextField
                        type="text"
                        id="standard-basic"
                        label="Folder name"
                        fullWidth
                        onChange={(e) => setFolder(e.target.value)}
                        value={folder}
                    />
                    <br />
                    <br />
                    <br />
                    <div className="justify-center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setModalOpen(false)}
                            style={{ backgroundColor: "#3F4D67", color: "white" }}
                        >
                            Close
            </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                            style={{ backgroundColor: "#3F4D67", color: "white" }}
                        >
                            Save and Close
            </Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}