import React, { useEffect } from "react";
import { PieChart, Pie, Legend, Cell } from "recharts";
import { Paper, Divider, Badge } from "@material-ui/core";
import { ResponsivePie } from "@nivo/pie";
import ReactApexChart from "react-apexcharts";
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { connectConfigure } from "react-instantsearch-core";

const Customlbel = () => {
  return <p>Label</p>;
};

export default function ChartBox({ chartdata, index, allData, search, total }) {
  // console.log(allData.totalResults);
  // console.log("check chart =>>>", chartdata);
  // let data = [
  //   {
  //     id: chartdata?.key[0].name,
  //     label: chartdata?.key[0].name,
  //     value: chartdata?.key[0].value,
  //     color: "hsl(76, 70%, 50%)",
  //   },
  //   {
  //     id: chartdata?.key[1].name + 1,
  //     label: chartdata?.key[1].name,
  //     value: Math.round(chartdata?.key[1].value),
  //     color: "hsl(104, 70%, 50%)",
  //   },
  // ];

  useEffect(() => {
    // const elem = document.getElementsByClassName(".apexcharts-datalabels");
    // console.log(elem)
  }, []);

  const options = {
    colors:
      index !== 0 && 1 && index !== 0 && 12
        ? ["#b19cd9", "#f3d567"]
        : ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0", "#5f78ab"],
    fill: {
      colors:
        index !== 0 && 1 && index !== 0 && 12
          ? ["#b19cd9", "#f3d567"]
          : ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0", "#5f78ab"],
    },
    chart: {
      width: 400,
      type: "donut",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "24px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: ["#ecf0fa"],
      },
      background: {},
    },
    labels:
      index === 0
        ? [
            chartdata?.key[0]?.name,
            chartdata?.key[1]?.name,
            chartdata?.key[2]?.name,
            chartdata?.key[3]?.name,
            chartdata?.key[4]?.name,
            chartdata?.key[12]?.name,
          ]
        : [chartdata?.key[0]?.name, chartdata?.key[1]?.name],

    legend: {
      show: false,
    },
    tooltip: {
      fillSeriesColor: false,
      enabled: true,
      // custom: function (opts) {
      //   const desc =
      //     opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
      //       .description;

      //   const value = opts.series[opts.seriesIndex][opts.dataPointIndex];

      //   return desc + ": " + value;
      // },
    },
    responsive:
      index === 0
        ? [
            {
              breakpoint: 750,
              options: {
                chart: {
                  width: 380,
                },
                legend: {
                  show: false,
                },
                dataLabels: {
                  offsetX: 1000,
                  offsetY: 1000,
                },
              },
            },
            {
              breakpoint: 650,
              options: {
                chart: {
                  width: 300,
                },
                legend: {
                  show: false,
                },
              },
            },
            {
              breakpoint: 570,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  show: false,
                },
              },
            },
          ]
        : [
            {
              breakpoint: 750,
              options: {
                chart: {
                  width: 340,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
  };
  const arrData =
    index === 0
      ? [
          chartdata?.key[5]?.value,
          chartdata?.key[6]?.value,
          chartdata?.key[7]?.value,
          chartdata?.key[8]?.value,
          chartdata?.key[9]?.value,
          chartdata?.key[12]?.value,
        ]
      : index === 1
      ? [chartdata?.key[0]?.value, chartdata?.key[1]?.value]
      : index === 4 ||
        index === 5 ||
        index === 6 ||
        index === 7 ||
        index === 9 ||
        index === 11
      ? [
          chartdata?.key[0]?.actualValue,
          chartdata?.key[1]?.actualValue < 0
            ? 0
            : chartdata?.key[1]?.actualValue,
        ]
      : [
          Math.round((chartdata?.key[0]?.value / allData?.totalResults) * 100),
          Math.round((chartdata?.key[1]?.value / allData?.totalResults) * 100),
        ];

  // console.log(chartdata);
  if (
    index === 0 ||
    index === 1 ||
    index === 4 ||
    index === 5 ||
    index === 6 ||
    index === 7 ||
    index === 9 ||
    index === 11
  ) {
    return (
      <div>
        <Grid
          container
          spacing={2}
          style={{
            backgroundColor: "#ffff",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          }}
        >
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactApexChart
              options={options}
              series={arrData?.length && arrData.length ? arrData : []}
              type="pie"
              width={500}
              className="crat-donut"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} style={{ paddingRight: "10px" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: 22,
                textAlign: "center",
              }}
            >
              {/* second chart headings */}
              {index === 0
                ? "Most Valuable Ad Types"
                : index === 1
                ? "Highly Edited Videos Vs Raw Cuts?"
                : index === 4
                ? "Should you offer a Discount on your ads?"
                : index === 5
                ? "Should you add a Testimonial to your ads?"
                : index === 6
                ? "Should you offer free shipping on your ads?"
                : index === 7
                ? "Should you offer Free Shipping?"
                : index === 9
                ? "Should you send users to a Homepage URL?"
                : index === 11
                ? "Should you use the Description Copy?"
                : ""}
            </p>
            <hr />
            {index === 0
              ? chartdata &&
                chartdata?.key?.length &&
                chartdata.key.map((e, i) => {
                  if ((i >= 5 && i <= 10 && e.value > 0) || i === 12) {
                    // console.log(chartdata.key[11].value, " " , e.value)

                    let pr = 100;

                    const total = chartdata.key[11].value / e.value;
                    const result = Math.round(total * pr);
                    let value = 100 - result ;
                    // if (i === 5) {
                    //   value = value / 10;
                    // }
                    console.log(value)
                    if (e.name === "dynamic_ads_views") {
                      var color = "#008ffb";
                    } else if (e.name === "social_proof_views") {
                      color = "#00e396";
                    } else if (e.name === "Product_Demo_views") {
                      color = "#ff4560";
                    } else if (e.name === "lifestyle_views") {
                      color = "#feb019";
                    } else if (e.name === "Spokesperson Anchor Views") {
                      color = "#775dd0";
                    } else if (e.name === "Case Study Views") {
                      color = "#feb019";
                    } else if (e.name === "Unboxing") {
                      color = "#5f78ab";
                    } else {
                      color = "grey";
                    }
                    // console.log(" == ", chartdata.key[11].value + "/", e.value, "=total=" + total)
                    // console.log('result ==', result, "--", result - 100)
                    if (value !== 0 && value != "Infinity" && value != "-Infinity") {
                      const label =
                        e?.name === "dynamic_ads_views"
                          ? "Dynamic Ads"
                          : e?.name === "social_proof_views"
                          ? "Social Proof"
                          : e?.name === "Product_Demo_views"
                          ? "Product Demo"
                          : e?.name === "lifestyle_views"
                          ? "Lifestyle"
                          : e?.name === "Spokesperson Anchor Views"
                          ? "Spokesperson Anchor"
                          : e?.name === "Case Study Views"
                          ? "Case Study"
                          : e?.name;
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          key={i}
                        >
                          {/* <Tooltip title={label} placement="top-start"
                          leaveDelay={200}
                        >
                          <Chip
                            style={{
                              fontWeight: "bold",
                              marginRight: 10,
                              fontSize: 22,
                              backgroundColor: color,
                              color: 'white',
                              borderRadius: 15,
                              padding: 5,
                            }}
                            label={label}
                          />
                        </Tooltip> */}
                          <p
                            style={{
                              fontWeight: "bold",
                              marginRight: 10,
                              fontSize: 22,
                              backgroundColor: color,
                              color: "white",
                              borderRadius: 20,
                              padding: 5,
                              textAlign: "center",
                            }}
                          >
                            {e?.name === "dynamic_ads_views"
                              ? "Dynamic Ads"
                              : e?.name === "social_proof_views"
                              ? "Social Proof"
                              : e?.name === "Product_Demo_views"
                              ? "Product Demo"
                              : e?.name === "lifestyle_views"
                              ? "Lifestyle"
                              : e?.name === "Spokesperson Anchor Views"
                              ? "Spokesperson Anchor"
                              : e?.name === "Case Study Views"
                              ? "Case Study"
                              : e?.name}
                          </p>
                          <p
                            style={{
                              // marginTop: '33px',
                              marginRight: -10,
                              fontWeight: "bold",
                              color: value < 0 ? "red" : "green",
                              fontSize: 22,
                            }}
                          >
                            {value === 0 &&
                              "No Uplift OR Decrease in Performance"}
                            {(value > 0 || value < 0) && (
                              <>
                                {value}%{" "}
                                {value < 0
                                  ? "Decrease in Performance"
                                  : "Uplift in Performance"}
                              </>
                            )}
                          </p>
                        </div>
                      );
                    }
                  }
                })
              : chartdata &&
                chartdata?.key?.length &&
                chartdata.key.map((e, i) => {
                  const total = chartdata.key[2]?.value / e?.value;
                  const result = Math.round(total * 100);
                  let value = 100 - result;
                  if (
                    index === 4 ||
                    index == 5 ||
                    index === 6 ||
                    index === 7 ||
                    index === 9 ||
                    index === 11
                  ) {
                    value = e.value;
                  }
                  // console.log(value, ' from ', e)
                  if (
                    e.name === "Highly_edited_videos" ||
                    e.name === "Description Used" ||
                    e.name === "Outbound URL is Homepage?" ||
                    e.name === "Includes Free Shipping" ||
                    e.name === "Mentions Shipping In Copy" ||
                    e.name === "Uplift in Performance" ||
                    e.name === "Includes Testimonial" ||
                    e.name === "Offer Discount"
                  ) {
                    var color ="#b19cd9";
                  } else if (
                    e.name === "Raw_cuts" ||
                    e.name === "Does Not Include Description" ||
                    e.name === "Outbound URL is not Homepage?" ||
                    e.name === "Does not include Free Shipping" ||
                    e.name === "Does Not Include Shipping" ||
                    e.name === "Does not include Testimonial" ||
                    e.name === "Does not offer Discount"
                  ) {
                    color = "#f3d567";
                  } else {
                    color = "black";
                  }
                  if (i < 2 && value != "Infinity") {
                    const label =
                      e?.name === "Highly_edited_videos"
                        ? "Highly Edited Videos"
                        : e?.name === "Raw_cuts"
                        ? "Raw Cuts"
                        : e?.name;
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        key={i}
                      >
                        {/* <Tooltip title={label}
                       placement="top-start"
                        leaveDelay={200}
                      >
                        <Chip
                          style={{
                            fontWeight: "bold",
                            marginRight: 10,
                            fontSize: 22,
                            backgroundColor: color,
                            color: 'white',
                            padding: 5,
                            display: 'flex',
                            width: 180,
                          }}
                          label={label}
                        />
                      </Tooltip> */}
                        <div
                          style={{
                            backgroundColor: color,
                            borderRadius: 15,
                            padding: 5,
                            marginRight: 20,
                            maxWidth: 200,
                          }}
                        >
                          {/* others sub heading */}
                          <p
                            style={{
                              fontWeight: "bold",
                              fontSize: 22,
                              color: "white",
                              margin: 0,
                              textAlign: "center",
                            }}
                          >
                            {e?.name === "Highly_edited_videos"
                              ? "Highly Edited Videos"
                              : e?.name === "Raw_cuts"
                              ? "Raw Cuts"
                              : e?.name}
                          </p>
                        </div>
                        <p
                          style={{
                            marginRight: -10,
                            fontWeight: "bold",
                            color: value < 0 ? "red" : "green",
                            fontSize: 22,
                          }}
                        >
                          {value === 0 &&
                            "No Uplift OR Decrease in Performance"}
                          {(value > 0 || value < 0) && (
                            <>
                              {value}%{" "}
                              {value < 0
                                ? "Decrease in Performance"
                                : "Uplift in Performance"}
                            </>
                          )}
                        </p>
                      </div>
                    );
                  }
                })}
          </Grid>
        </Grid>
      </div>
    );
  } else {
    // console.log(index, "on=", chartdata?.key[0].name)
    return (
      <div>
        <Grid
          container
          spacing={1}
          style={{
            backgroundColor: "#ffff",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            borderRadius: "10px",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{ textAlign: "center", padding: "0px 30px 0px 30px" }}
          >
            <h5>
              {index === 0
                ? "Ad Type"
                : index === 1
                ? "Highly edited videos vs raw cuts"
                : index === 2
                ? " Average Headline "
                : index === 3
                ? " Average Word Count for Headline Text"
                : index === 4
                ? "Should you offer a Discount on your ads? "
                : index === 5
                ? "Should you add a Testimonial to your ads? "
                : index === 6
                ? "Should you offer free shipping on your ads?"
                : index === 7
                ? "Should you offer Free Shipping?"
                : index === 8
                ? "How Many Ads Use “!” On Their Headline Copy?"
                : index === 9
                ? "Should you send users to a Homepage URL?"
                : index === 10
                ? "How Many Ads Have Their Landing Page URL In The Primary Text Copy?"
                : index === 11
                ? "Should you use the Description Copy?"
                : index === 12
                ? " How Many Ads Mention “®” Or “™” In Their Copy?"
                : index === 17
                ? " How Many Ads Use The Word “You” In Their Copy?"
                : ""}
            </h5>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ReactApexChart
              options={options}
              series={arrData?.length ? arrData : []}
              type="pie"
              width={400}
              className="crat-donut"
            />
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              textAlign: "left",
            }}
          >
            <div
              style={{
                backgroundColor: "#008ffb",
                width: 10,
                height: 10,
                borderRadius: "50%",
              }}
            />
            <Badge
              variant="filled"
              severity="info"
              style={{ paddingLeft: "10px" }}
            >
              {chartdata?.key[0].name}
            </Badge>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              textAlign: "left",
            }}
          >
            <div
              style={{
                backgroundColor: "#00e396",
                width: 10,
                height: 10,
                borderRadius: "50%",
              }}
            />
            <Badge
              variant="filled"
              severity="info"
              style={{ paddingLeft: "10px" }}
            >
              {chartdata?.key[1].name}
            </Badge>
          </Grid>
        </Grid>
      </div>
    );
  }
}
