import React, { useState, useEffect } from "react";
import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useStateValue } from "../../Providers/UserProvider";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        letterSpacing: "10px",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "30%",
        backgroundColor: "#fff",
        padding: "30px",
        borderRadius: "20px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)",
        textAlign: "center",
        marginTop: "5rem",
        [theme.breakpoints.down("md")]: {
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        textAlign: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[7],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "50%",
        textAlign: "center",
        padding: "20px",
        border: "none",
        outline: "none",
        borderRadius: "20px",
    },
}));

export default function EditModal({
    setModalOpen, modalOpen, selected, folderName, getFolder
}) {
    const [folder, setFolder] = useState("");
    const [{ user }] = useStateValue();
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const handleClose = async () => {
        setModalOpen(false);
    };

    useEffect(() => {
    }, [modalOpen, folderName])

    const handleEdit = async () => {
        setLoading(true)
        const headers = { token: user?.token }
        const userId = user?.data?._id;
        const response =
            await axios.post(`https://projectb2.herokuapp.com/updateFavouriteFolder`,
                { favouriteFolderId: selected, user: userId, name: folder },
                { headers }
            );
        if (response.data?.msg === "Favourite folder name updated successfully") {
            setLoading(false)
            getFolder();
            setModalOpen(false)
            setFolder("");
        } else {
            setLoading(false)
            setModalOpen(false)
            alert(response.data?.msg)
        }
    }



return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={modalOpen}>
            <div className={classes.paper}>
                <Grid item md={12} sm={12} xs={12}>
                    <h3 style={{ color: "#3F4D67" }}>
                        Rename Folder:
              </h3>
                    <TextField
                        type="text"
                        id="standard-basic"
                        label="Rename Folder"
                        fullWidth
                        onChange={(e) => setFolder(e.target.value)}
                        value={folder}
                    />
                    {loading && <CircularProgress />}
                </Grid>
                <br />
                <br />
                <br />
                <div className="justify-center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        style={{ backgroundColor: "#3F4D67", color: "white" }}
                    >
                        Close
            </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEdit}
                        style={{ backgroundColor: "grey", color: "white" }}
                    >
                        Save
            </Button>
                </div>
            </div>
        </Fade>
    </Modal>
)
}